import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import moment from "moment";

export const addBulkFleetColumns: (
  t: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (t) => [
  {
    title: t("car_number"),
    dataIndex: "num",
    key: "num",
    render: (value, record) => `${record.serie} ${t("TU")} ${value}`,
  },
  {
    title: "VIN",
    dataIndex: "vin",
    key: "vin",
  },
  {
    title: t("manufacturer"),
    dataIndex: "manufacturer",
    key: "manufacturer",
  },
  {
    title: t("car_model"),
    dataIndex: "modal",
    key: "modal",
  },

  {
    title: t("type"),
    dataIndex: "type",
    render: (value: string) => t(value),
  },
  {
    title: t("color"),
    dataIndex: "color",
    render: (value: string) => t(value),
  },
  {
    title: t("nb_places"),
    dataIndex: "places",
  },
  {
    title: t("power"),
    dataIndex: "numberHorses",
    render: (value: string) => `${value} ${t("horse_power")}`,
  },
  {
    title: t("fuel"),
    dataIndex: "fuel",
    render: (value: string) => t(value),
  },
  {
    title: t("first_registration"),
    dataIndex: "firstRegistration",
    key: "firstRegistration",
    render: (value: string) => moment(value).format("DD/MM/YYYY"),
  },
  {
    title: t("transmission"),
    dataIndex: "transmission",
    key: "transmission",
    render: (value) => t(value),
  },

  {
    title: t("price_per_day"),
    dataIndex: "price_per_day",
    key: "price_per_day",
    render: (value: string) => `${Number(value).toFixed(3)} ${t("TND")}`,
  },
  {
    title: t("purchase_price"),
    dataIndex: "purchase_price",
    key: "purchase_price",
    render: (value: string) => `${Number(value).toFixed(3)} ${t("TND")}`,
  },
  {
    title: t("OPERATING_CARD_NUMBER"),
    dataIndex: "operatingCardNumber",
    key: "operatingCardNumber",
  },
  {
    title: t("OPERATING_CARD_VALID_MONTHS"),
    dataIndex: "operatingCardValidMonthes",
    key: "operatingCardValidMonthes",
    render: (value: string) => `${Number(value)} ${t("MOIS")}`,
  },
  {
    title: t("OPERATING_CARD_VALID_FROM"),
    dataIndex: "operatingCardValidFrom",
    key: "operatingCardValidFrom",
    render: (value: string) => moment(value).format("DD/MM/YYYY"),
  },
];
