import { ForgotPassHandlerPropsT } from "../../Types";
import { Axios } from "../../Config";

export const ForgotPassHandler = async ({ email }: ForgotPassHandlerPropsT) => {
  return Axios.get(`/authn/forgot-password?email=${email}`);
};

export const ForgotPassWithOtpHandler = async ({
  email,
  code,
  newPassword,
  confirmPassword,
}: any) => {
  return Axios.post("/authn/forgot-password", {
    email,
    code,
    newPassword,
    confirmPassword,
  });
};
