import React, { useState } from "react";
import { Bills, Clients } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import {
  ChevronDown,
  ChevronUp,
  HandCoins,
  Hash,
  ReceiptText,
  SquarePen,
  UserRoundPen,
} from "lucide-react";
import { Badge, Button, List, Modal } from "antd";
import { useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import { useCompanyBillsData } from "../../../../../Hooks/useCompanyBillsData";
import { CompanyBillPrintable } from "../../../../UI/Printables/CompanyBillPrintable";

interface ClientFilesProps {
  client: Clients | null;
}

const BillsCard = ({
  bills,
  chooseBillHandler,
}: {
  bills: Bills[];
  chooseBillHandler: (billID: string) => void;
}) => {
  const { t } = useTranslation();

  const data = bills.map((bill) => {
    return {
      billID: bill.billID,
      createdAt: bill.createdAt,
      createdBy: bill.createdBy,
      title: bill.reference,
      amount: bill.amount,
      status: bill.status,
    };
  });

  return (
    <List
      itemLayout="vertical"
      size="default"
      pagination={{
        pageSize: 6,
      }}
      locale={{ emptyText: t("no_invoices") }}
      dataSource={data}
      footer={null}
      renderItem={(item) => (
        <List.Item
          key={item.billID}
          extra={
            <div className="flex w-full h-full items-end justify-end">
              <Button onClick={() => chooseBillHandler(item.billID)}>
                {t("view_invoice")}
              </Button>
            </div>
          }
          actions={[
            <div className="flex items-center gap-4">
              <HandCoins size={15} className="text-gray-400" />
              <span>
                {Number(item.amount).toFixed(3)} {t("TND")}
              </span>
            </div>,
            <div className="flex items-center gap-4">
              <HandCoins size={15} className="text-gray-400" />
              <span>{t("TND")}</span>
            </div>,
          ]}
        >
          <List.Item.Meta
            title={
              <div className="flex items-center gap-3">
                <Hash size={15} className="text-gray-400" /> {item.title}
              </div>
            }
            description={
              <div className="w-full md:w-1/2 max-w-80 font-normal">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <SquarePen size={15} className="text-gray-400" />
                    <span className="text-sm text-gray-600">
                      {t("date_of_creations")} :
                    </span>
                  </div>
                  <span className="text-sm font-medium">
                    {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <UserRoundPen className="text-gray-400" size={15} />
                    <span className="text-sm text-gray-600">
                      {t("created_by")} :
                    </span>
                  </div>
                  <span className="text-sm font-medium">{`${item.createdBy.name} ${item.createdBy.lastName}`}</span>
                </div>
              </div>
            }
            className="font-bold"
          />
        </List.Item>
      )}
    />
  );
};

const ClientBills: React.FC<ClientFilesProps> = ({ client }) => {
  const { t } = useTranslation();

  const { Bills } = client || { Bills: [] };
  const { bills: CompanyBills } = useCompanyBillsData();
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState<null | string>(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const [isContentOpen, setIsContentOpen] = useState(false);

  const chooseBillHandler = (BillID: string | null) => {
    setOpen(true);
    setSelectedBill(BillID);
  };

  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <ReceiptText size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("client_invoices").toUpperCase()}{" "}
          </span>
          <Badge count={Bills.length} style={{ backgroundColor: "#2c3e50" }} />
        </div>
        {isContentOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsContentOpen(!isContentOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsContentOpen(!isContentOpen);
            }}
          />
        )}
      </div>
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
        >
          <CompanyBillPrintable
            CompanyBill={
              CompanyBills.filter((bill) => bill.billID === selectedBill)[0]
            }
          />
        </Modal>
      )}
      {isContentOpen ? (
        <div className="mt-6">
          <BillsCard bills={Bills} chooseBillHandler={chooseBillHandler} />
        </div>
      ) : null}
    </div>
  );
};

export default ClientBills;
