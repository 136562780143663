import React, { useCallback, useMemo, useState } from "react";
import { Scheduler } from "@bitnoi.se/react-scheduler";
import "@bitnoi.se/react-scheduler/dist/style.css";
import { useTranslation } from "react-i18next";
import { RentContracts } from "../../../../Types";
import dayjs_fr from "dayjs/locale/fr";
import dayjs_ar from "dayjs/locale/ar";
import moment from "moment";

function FleetScheduler({ contracts, maxRecordsPerPage = 20 }: any) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const colors = [
    "#2980b9",
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#e67e22",
    "#e74c3c",
    "#d35400",
    "#e17055",
    "#fd79a8",
    "#74b9ff",
    "#eb4d4b",
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#e67e22",
    "#e74c3c",
    "#d35400",
    "#e17055",
    "#fd79a8",
    "#74b9ff",
    "#eb4d4b",
  ];

  const contractToDisplay = useMemo(() => {
    return contracts
      .filter((contract: RentContracts) => !contract.isCanceled)
      .map((contract: any, index: any) => {
        return {
          id: contract.fleetID,
          label: {
            title: `${contract.registrationNumber.replaceAll("/", t(" TU "))}`,
            subtitle: `${contract.manufacturer} ${contract.modal} |  ${t(contract.color).toUpperCase()}  `,
          },
          data: contract.RentContract.map((rent: RentContracts) => {
            return {
              id: rent.rentContractID,
              startDate: new Date(rent.startDate),
              endDate: new Date(rent.endDate),
              occupancy: 11111,
              title: `${rent.client.fullName || rent.client.denomination}`,
              subtitle: `${moment(rent.startDate).format("DD/MM/YYYY HH:mm")} -> ${moment(rent.endDate).format("DD/MM/YYYY HH:mm")}`,
              bgColor: colors[index],
            };
          }),
        };
      });
  }, [contracts, t]);

  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleRangeChange = useCallback((range: any) => {
    setRange(range);
  }, []);

  const isDateInRange = (
    projectStartDate: string,
    projectEndDate: string,
    rangeStartDate: string,
    rangeEndDate: string,
  ) => {
    const startDate = new Date(projectStartDate).getTime();
    const endDate = new Date(projectEndDate).getTime();
    const rangeStart = new Date(rangeStartDate).getTime();
    const rangeEnd = new Date(rangeEndDate).getTime();

    return (
      (startDate >= rangeStart && startDate <= rangeEnd) ||
      (endDate >= rangeStart && endDate <= rangeEnd) ||
      (startDate <= rangeStart && endDate >= rangeEnd)
    );
  };

  const filteredSchedulerData: any = contractToDisplay.map((person: any) => ({
    ...person,
    data: person.data.filter((project: any) =>
      isDateInRange(
        project.startDate,
        project.endDate,
        new Date(range.startDate).toISOString(),
        new Date(range.endDate).toISOString(),
      ),
    ),
  }));

  const translations = [
    {
      id: "fr",
      lang: {
        feelingEmpty: "Vide",
        free: "Libre",
        loadNext: "Charger Suivant",
        loadPrevious: "Charger Précédent",
        over: "Terminé",
        taken: "Pris",
        topbar: {
          filters: "Filtres",
          next: "Suivant",
          prev: "Précédent",
          today: "Aujourd'hui",
          view: "Voir",
        },
        search: "Rechercher",
        week: "Semaine",
      },
      translateCode: "fr",
      dayjsTranslations: dayjs_fr,
    },
    {
      id: "ar",
      lang: {
        feelingEmpty: "فراغ",
        free: "مجاني",
        loadNext: "تحميل التالي",
        loadPrevious: "تحميل السابق",
        over: "انتهى",
        taken: "محجوز",
        topbar: {
          filters: "فلاتر",
          next: "التالي",
          prev: "السابق",
          today: "اليوم",
          view: "عرض",
        },
        search: "بحث",
        week: "أسبوع",
      },
      translateCode: "ar",
      dayjsTranslations: dayjs_ar,
    },
  ];

  return (
    <Scheduler
      data={filteredSchedulerData}
      onRangeChange={handleRangeChange}
      config={{
        zoom: 1,
        showTooltip: false,
        translations,
        showThemeToggle: false,
        isFiltersButtonVisible: false,
        filterButtonState: -1,
        lang: language,
        maxRecordsPerPage,
      }}
    />
  );
}

export default FleetScheduler;
