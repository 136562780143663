import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberToLetter } from "../../../Utils/chiffresEnLettre";
import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import moment from "moment/moment";
import { fileUrlHandler } from "../../../Utils";
import { Download } from "lucide-react";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export const CompanyBillPrintableV2 = ({
  showPrint = true,
  showAsIcon = false,
  CompanyBill,
}: any) => {
  const { t } = useTranslation();
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    QRCode.toDataURL(CompanyBill.billID, { width: 100 }, (error, url) => {
      if (error) {
        console.error("Error generating QR code:", error);
      } else {
        setQrCodeUrl(url);
      }
    });
  }, [CompanyBill.billID]);

  if (!CompanyBill) {
    return null;
  }

  const sommeEnLettre = NumberToLetter(
    CompanyBill.amount + 1,
    "Dinars",
    "Millimes",
  );

  const Content = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Company Info */}
          <View style={styles.header}>
            <View style={styles.companyInfo}>
              <Image
                style={{ width: 150 }}
                src={fileUrlHandler(CompanyBill.company.logo.name)}
              />
            </View>

            <View style={styles.invoiceInfo}>
              <Text style={styles.title}>FACTURE</Text>
              <Text style={styles.subtitle}>
                {CompanyBill.reference || "FACTURE #"}
              </Text>
              {qrCodeUrl && (
                <Image
                  src={qrCodeUrl}
                  style={{ width: 70, marginLeft: "auto", marginBottom: 10 }}
                />
              )}
              <View style={styles.billDetails}>
                <Text>
                  Date:{" "}
                  {moment(CompanyBill.createdAt).format("DD/MM/YYYY HH:mm")}
                </Text>
                <Text>
                  Date d'échéance:{" "}
                  {moment(CompanyBill.dueDate).format("DD/MM/YYYY HH:mm")}
                </Text>{" "}
              </View>
            </View>
          </View>

          {/* Client Info */}
          <View style={styles.invoiceDetails}>
            <Text style={styles.invoiceDetailsTitle}>CLIENT</Text>
            <Text>
              {CompanyBill?.client?.fullName?.toUpperCase() ||
                CompanyBill?.client?.denomination?.toUpperCase() ||
                ""}
            </Text>
            <Text> {CompanyBill.client.address}</Text>
            <Text>{CompanyBill.client.city}</Text>
            <Text>
              MF: {CompanyBill.client.idNumber || CompanyBill.client.vatNumber}
            </Text>
            <Text>Mobile: {CompanyBill.client.phone}</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol1}>
                <Text style={styles.cell}>N°</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.cell}>Description</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.cell}>Quantité</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.cell}>TVA</Text>
              </View>
              <View style={styles.tableCol5}>
                <Text style={styles.cell}>PU TTC</Text>
              </View>
              <View style={styles.tableCol6}>
                <Text style={styles.cell}>Total TTC</Text>
              </View>
            </View>

            {/* Table Rows */}
            {CompanyBill?.BillsItems?.map((item, index) => (
              <View key={index} style={styles.tableRow} wrap={true}>
                <View style={styles.tableCol1}>
                  <Text style={styles.cell}>{index + 1}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.cell}>{item.name}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.cell}>{item.quantity}</Text>
                </View>
                <View style={styles.tableCol4}>
                  <Text style={styles.cell}>19%</Text>
                </View>
                <View style={styles.tableCol5}>
                  <Text style={styles.cell}>
                    {" "}
                    {Number(item.price).toFixed(3)} DNT
                  </Text>
                </View>
                <View style={styles.tableCol6}>
                  <Text style={styles.cell}>
                    {" "}
                    {Number(item.total).toFixed(3)} DNT
                  </Text>
                </View>
              </View>
            ))}
          </View>

          {/* Totals */}
          <View style={styles.totals}>
            <View style={styles.totalRow}>
              <Text>TOTAL HT</Text>
              <Text>
                {Number(CompanyBill.amount / Number(1.19)).toFixed(3)} DNT
              </Text>
            </View>
            <View style={styles.totalRow}>
              <Text>TVA 19%</Text>
              <Text>
                {" "}
                {Number(
                  CompanyBill.amount - CompanyBill.amount / Number(1.19),
                ).toFixed(3)}{" "}
                DNT{" "}
              </Text>
            </View>
            <View style={styles.totalRow}>
              <Text>Timbre fiscal</Text>
              <Text>1,000 DNT</Text>
            </View>
            <View style={styles.totalRow}>
              <Text>NET À PAYER</Text>
              <Text> {Number(CompanyBill.amount + 1).toFixed(3)} DNT</Text>
            </View>
          </View>

          <Text style={{ marginTop: 40, width: "40%" }}>
            Arrete la presente facture a la somme de{" "}
            {sommeEnLettre.includes("Dinars")
              ? sommeEnLettre.toUpperCase()
              : `${sommeEnLettre.toUpperCase()} DINARS`}
          </Text>

          {/* Footer */}
          <View style={styles.footer} fixed={true}>
            <View style={styles.footerSection}>
              <Text>{CompanyBill.company.rsLatin}</Text>
              <Text>{CompanyBill.company.mainAddress}</Text>
              <Text>MF: {CompanyBill.company.VAT}</Text>
            </View>
            <View style={styles.footerSection}>
              <Text style={styles.footerText}>INFORMATIONS DE CONTACT</Text>
              <Text style={styles.footerText}>
                M {CompanyBill.company.phone}
              </Text>
              <Text style={styles.footerText}>
                @ {CompanyBill.company.email}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };
  if (showAsIcon) {
    return (
      <PDFDownloadLink
        document={<Content />}
        fileName={`${CompanyBill.referance || "001"}.pdf`}
      >
        <Download size={14} />
      </PDFDownloadLink>
    );
  }
  return (
    <div
      className="p-4 bg-white rounded-lg overflow-hidden"
      dir="ltr"
      style={{ fontSize: 10 }}
    >
      {showPrint && !showAsIcon && (
        <div className="flex justify-end mb-4">
          <PDFDownloadLink
            document={<Content />}
            fileName={`${CompanyBill.referance || "001"}.pdf`}
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white flex justify-center items-center rounded"
          >
            {t("print_bill").toUpperCase()}
          </PDFDownloadLink>
        </div>
      )}

      <PDFViewer className="w-full min-h-[700px]">
        <Content />
      </PDFViewer>
    </div>
  );
};
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  companyInfo: {
    width: "50%",
  },
  invoiceInfo: {
    width: "40%",
  },

  billDetails: {
    borderTopWidth: 2,
    backgroundColor: "#f0f0f0",
    borderBottomColor: "#2980b9",
    padding: 10,
    gap: 10,
  },
  title: {
    textAlign: "right",
    fontSize: 20,
    color: "#2c3e50",
    marginBottom: 10,
    fontWeight: 700,
  },
  subtitle: {
    textAlign: "right",
    fontSize: 16,
    color: "#2c3e50",
    marginBottom: 20,
    fontWeight: 700,
  },
  invoiceDetails: {
    width: "40%",
    marginBottom: 30,
    gap: 3,
    borderTopWidth: 2,
    backgroundColor: "#f0f0f0",
    borderBottomColor: "#2980b9",
    padding: 10,
  },
  invoiceDetailsTitle: {
    marginBottom: 10,
    fontSize: 14,
  },
  table: {
    width: "100%",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    alignItems: "center",
    minHeight: 24,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
  },
  tableCol1: {
    width: "10%",
  },
  tableCol2: {
    width: "40%",
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  tableCol3: {
    width: "10%",
  },
  tableCol4: {
    width: "10%",
  },
  tableCol5: {
    width: "15%",
  },
  tableCol6: {
    width: "15%",
  },
  cell: {
    padding: 5,
  },
  totals: {
    width: "50%",
    alignSelf: "flex-end",
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
  },
  footer: {
    flexDirection: "row",
    width: "100%",
    marginTop: "auto",
    gap: 20,
    justifyContent: "space-between",
  },
  footerSection: {
    flexDirection: "column",
    width: "90%",
  },
});
