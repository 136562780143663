import React, { useMemo } from "react";
import { Account, Transactions } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ExportData({ Account }: { Account: Account }) {
  const { t } = useTranslation();

  const tableHeader = useMemo(() => {
    const TransactionKeys: Array<keyof Transactions> = [
      "Type d'opération",
      "Montant",
      "Description",
      "Date d'opération",
    ].map((key) => t(key));
    return TransactionKeys;
  }, [t]);

  const availablePeriods = {
    TODAY: {
      label: t("TODAY"),
      value: "TODAY",
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
    },
    YESTERDAY: {
      label: t("YESTERDAY"),
      value: "YESTERDAY",
      startDate: moment().subtract(1, "day").startOf("day"),
      endDate: moment().subtract(1, "day").endOf("day"),
    },
    LAST_WEEK: {
      label: t("LAST_WEEK"),
      value: "LAST_WEEK",
      startDate: moment().subtract(1, "week").startOf("week"),
      endDate: moment().subtract(1, "week").endOf("week"),
    },
    LAST_MONTH: {
      label: t("LAST_MONTH"),
      value: "LAST_MONTH",
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
    },
    SINCE_START_OF_YEAR: {
      label: t("SINCE_START_OF_YEAR"),
      value: "SINCE_START_OF_YEAR",
      startDate: moment().startOf("year"),
      endDate: moment(),
    },
  };

  const transactions = useMemo(() => {
    return Account.transactions;
  }, [Account]);

  const exportToPDF = (startDate: moment.Moment, endDate: moment.Moment) => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = moment(transaction.createdAt);
      return transactionDate.isBetween(startDate, endDate, undefined, "[]");
    });

    const doc = new jsPDF();
    doc.setProperties({ title: t("transaction_history") });
    doc.setFontSize(8);

    const generateSummaryTable = () => {
      const transactionSumsByType = filteredTransactions.reduce(
        (acc: Record<string, number>, transaction) => {
          const { type, amount } = transaction;
          acc[type] = (acc[type] || 0) + Number(amount);
          return acc;
        },
        {},
      );

      const summaryRows = Object.entries(transactionSumsByType).map(
        ([type, total]) => [t(type), `${total.toFixed(3)} TND`],
      );

      const startY = doc.lastAutoTable?.finalY
        ? doc.lastAutoTable.finalY + 10
        : 20;

      if (summaryRows.length > 0) {
        doc.autoTable({
          startY: startY,
          head: [[t("Type d'opération"), t("Montant total")]],
          body: summaryRows,
          headStyles: { fillColor: [51, 51, 51] },
          styles: { fontSize: 8 },
        });
      }
    };

    const generateTransactionTable = () => {
      const rows = filteredTransactions.map((transaction) => [
        t(transaction.type),
        `${Number(transaction.amount).toFixed(3)} TND`,
        transaction.description?.replaceAll("<br>", "\n") || "N/A",
        moment(transaction.createdAt).format("DD/MM/YYYY HH:mm"),
      ]);

      doc.autoTable({
        startY: 30,
        head: [tableHeader],
        body: rows,
        headStyles: { fillColor: [51, 51, 51] },
        styles: { fontSize: 8 },
        columnStyles: {
          2: { cellWidth: 60 },
        },
      });
    };

    doc.setFontSize(10);
    doc.text(
      `${t("transaction_history")} de ${formattedStartDate} Au ${formattedEndDate}`,
      20,
      20,
    );
    doc.text(` ${Account.name}`, 20, 25);
    generateTransactionTable();
    generateSummaryTable();

    doc.save(
      `${t("transaction_history")}_${formattedStartDate}_to_${formattedEndDate}.pdf`,
    );
  };

  const items: MenuProps["items"] = useMemo(() => {
    return Object.values(availablePeriods).map(
      ({ label, startDate, endDate }, index) => {
        return {
          key: index,
          label: (
            <span onClick={() => exportToPDF(startDate, endDate)}>
              {`${t("transaction_history")} : ${label}`}
            </span>
          ),
        };
      },
    );
  }, [availablePeriods, t]);

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Space>
        {t("EXPORT")}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

export default ExportData;
