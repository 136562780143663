import React from "react";
import { NumberToLetter } from "../../../Utils/chiffresEnLettre";
import {
  Document,
  Font,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Download } from "lucide-react";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export const RecuPrintable = ({ RentContractPayement }: any) => {
  if (!RentContractPayement) {
    return null;
  }

  const sommeEnLettre = NumberToLetter(
    RentContractPayement.paymentInfo.amount,
    "Dinars",
    "Millimes",
  );

  const { receiptNumber, date, companyInfo, paymentInfo } =
    RentContractPayement;

  const Content = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.watermark}>
          <Text>REÇU</Text>
        </View>

        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.companyName}>
              {companyInfo.name.toUpperCase()}
            </Text>
            <View style={styles.companyInfo}>
              <Text>{companyInfo.address}</Text>
              <Text>Tél: {companyInfo.phone}</Text>
              <Text>Email: {companyInfo.email}</Text>
              <Text>N° TVA: {companyInfo.taxId}</Text>
            </View>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.receiptDate}>Date: {date}</Text>
          </View>
        </View>

        <Text style={styles.documentTitle}>Reçu de Paiement</Text>

        <View style={styles.clientSection}>
          <Text style={styles.sectionTitle}>Informations Client</Text>
          <Text style={{ marginBottom: 5 }}>{paymentInfo.clientName}</Text>
          <Text>{paymentInfo.clientAddress}</Text>
        </View>

        <View style={styles.paymentDetails}>
          <Text style={styles.sectionTitle}>Détails du Paiement</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Facture N°:</Text>
            <Text style={styles.value}>{paymentInfo.invoiceNumber}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Mode de paiement:</Text>
            <Text style={styles.value}>{paymentInfo.paymentMethod}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Date de paiement:</Text>
            <Text style={styles.value}>{paymentInfo.paymentDate}</Text>
          </View>
        </View>

        <View style={styles.amountSection}>
          <Text style={styles.sectionTitle}>Montant Payé</Text>
          <Text style={styles.amountValue}>{paymentInfo.amount} TND</Text>
          <Text style={styles.amountInWords}>
            {sommeEnLettre.toLowerCase()}
          </Text>
        </View>

        <View style={styles.signature}>
          <View style={styles.signatureBox}>
            <Text>Signature et cachet</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>
            Ce reçu atteste du paiement de la facture mentionnée ci-dessus.
          </Text>
          <Text>Document généré par LOCASAFE</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="w-full flex justify-center items-center">
      <PDFDownloadLink
        document={<Content />}
        fileName={`${receiptNumber || "001"}.pdf`}
      >
        <Download size={14} />
      </PDFDownloadLink>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
    fontFamily: "Helvetica",
    backgroundColor: "#FFFFFF",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 40,
    borderBottom: "2pt solid #1a365d",
    paddingBottom: 20,
  },
  headerLeft: {
    width: "60%",
  },
  headerRight: {
    width: "35%",
    textAlign: "right",
  },
  companyName: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#1a365d",
    marginBottom: 10,
  },
  companyInfo: {
    width: "60%",
    fontSize: 10,
  },
  documentTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#1a365d",
    textAlign: "center",
    marginBottom: 30,
    textTransform: "uppercase",
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1pt solid #e2e8f0",
    borderTop: "1pt solid #e2e8f0",
  },
  receiptNumber: {
    fontSize: 14,
    color: "#4a5568",
    textAlign: "right",
    marginBottom: 5,
  },
  receiptDate: {
    fontSize: 10,
    color: "#4a5568",
    textAlign: "right",
    marginBottom: 20,
  },
  clientSection: {
    backgroundColor: "#f7fafc",
    padding: 15,
    borderRadius: 5,
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#1a365d",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    marginBottom: 8,
    padding: 5,
  },
  label: {
    width: 180,
    color: "#4a5568",
    fontWeight: "bold",
  },
  value: {
    flex: 1,
    color: "#2d3748",
  },
  paymentDetails: {
    marginBottom: 10,
    borderLeft: "3pt solid #1a365d",
    paddingLeft: 15,
  },
  amountSection: {
    backgroundColor: "#f7fafc",
    padding: 20,
    marginTop: 20,
    borderRadius: 5,
  },
  amountValue: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1a365d",
  },
  amountInWords: {
    fontStyle: "italic",
    color: "#4a5568",
    marginTop: 5,
  },
  signature: {
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 50,
  },
  signatureBox: {
    width: 200,
    textAlign: "center",
  },
  signatureLine: {
    width: "100%",
    borderBottom: "1pt solid #CBD5E0",
    marginTop: 40,
    marginBottom: 10,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: "center",
    fontSize: 6,
    paddingTop: 20,
  },
  watermark: {
    position: "absolute",
    top: "40%",
    left: "20%",
    right: "20%",
    transform: "rotate(-45deg)",
    fontSize: 60,
    color: "#f7fafc",
    textAlign: "center",
    opacity: 0.1,
  },
});
