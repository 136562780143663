import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, QRCode } from "antd";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { fileUrlHandler } from "../../../Utils";
import { NumberToLetter } from "../../../Utils/chiffresEnLettre";
import { BillsItems } from "../../../Types";

export const CompanyBillPrintable = ({
  showPrint = true,
  CompanyBill,
}: any) => {
  const { t } = useTranslation();
  const print = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  if (!CompanyBill) {
    return null;
  }

  const sommeEnLettre = NumberToLetter(
    CompanyBill.amount + 1,
    "Dinars",
    "Millimes",
  );

  return (
    <div
      className="p-4 bg-white rounded-lg overflow-hidden"
      dir="ltr"
      style={{ fontSize: 10 }}
    >
      {showPrint && (
        <div className="flex justify-end mb-4">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
            onClick={handlePrint}
          >
            {t("print_bill").toUpperCase()}
          </Button>
        </div>
      )}

      <div className="max-w-[85rem] px-4 mx-auto my-4">
        <div ref={print} className="flex flex-col">
          <div className="bg-white p-9 rounded-lg min-h-[600px]">
            <div className="flex justify-between items-start">
              <div className="flex flex-col gap-4">
                <img
                  width={100}
                  alt=""
                  srcSet={fileUrlHandler(CompanyBill.company.logo.name)}
                />
                <QRCode
                  value={CompanyBill.billID}
                  className="pt-4"
                  bordered={false}
                  size={80}
                />
              </div>

              <div className="text-right">
                <h1 className="text-lg font-semibold">
                  {CompanyBill.company.rsLatin}
                </h1>
                <address className="mt-2 not-italic text-gray-800">
                  <span>{CompanyBill.company.mainAddress}</span>
                  <br />
                  MF: {CompanyBill.company.VAT}
                </address>
                <p className="text-sm text-gray-500">
                  {CompanyBill.company.email}
                </p>
                <p className="text-sm text-gray-500">
                  {CompanyBill.company.phone}
                </p>
                <h2 className="text-xl font-semibold mt-4">
                  {CompanyBill.reference || "FACTURE #"}
                </h2>
                <span className="text-gray-500">{CompanyBill.billID}</span>
              </div>
            </div>

            <div className="mt-6 grid sm:grid-cols-2 gap-3">
              <div>
                <h3 className="text-base font-semibold text-gray-800">
                  CLIENT:
                </h3>
                <h3 className="text-base font-semibold text-gray-800">
                  {CompanyBill?.client?.fullName?.toUpperCase() ||
                    CompanyBill?.client?.denomination?.toUpperCase() ||
                    ""}
                </h3>
                <address className="mt-1 not-italic text-gray-500">
                  MF:{" "}
                  {CompanyBill.client.idNumber || CompanyBill.client.vatNumber}
                  <br />
                  Adresse: {CompanyBill.client.address}
                  <br />
                  {CompanyBill.client.city}
                  <br />
                  {CompanyBill.client.phone}
                </address>
              </div>

              <div className="text-right space-y-1">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Crée le:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {moment(CompanyBill.createdAt).format("DD/MM/YYYY HH:mm")}
                    </dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Date d'échéance:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {moment(CompanyBill.dueDate).format("DD/MM/YYYY HH:mm")}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="border border-gray-200 p-3 rounded-lg space-y-2">
                <div className="hidden sm:grid sm:grid-cols-5">
                  <div className="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">
                    Description
                  </div>
                  <div className="text-start text-xs font-medium text-gray-500 uppercase">
                    Quantité
                  </div>
                  <div className="text-start text-xs font-medium text-gray-500 uppercase">
                    Prix Unitaire TTC
                  </div>
                  <div className="text-end text-xs font-medium text-gray-500 uppercase">
                    Total TTC
                  </div>
                </div>

                <div className="hidden sm:block border-b border-gray-200"></div>
                {CompanyBill?.BillsItems?.map((item: BillsItems) => (
                  <Fragment key={item.billItemID}>
                    <div className="grid grid-cols-3 sm:grid-cols-5 gap-1">
                      <div className="col-span-full sm:col-span-2">
                        <p className="font-medium text-xs text-gray-800">
                          {item.name}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-800 text-xs">{item.quantity}</p>
                      </div>
                      <div>
                        <p className="text-gray-800 text-xs">
                          {Number(item.price).toFixed(3)} DNT
                        </p>
                      </div>
                      <div>
                        <p className="sm:text-end text-xs text-gray-800">
                          {Number(item.total).toFixed(3)} DNT
                        </p>
                      </div>
                    </div>
                    <div className="sm:hidden border-b border-gray-200"></div>
                  </Fragment>
                ))}
              </div>
            </div>

            <div className="mt-6 flex sm:justify-end">
              <div className="w-full max-w-2xl sm:text-end space-y-1">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Sous-Total HT
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {Number(CompanyBill.amount / Number(1.19)).toFixed(3)} DNT
                    </dd>
                  </dl>

                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      TVA 19%:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {Number(
                        CompanyBill.amount - CompanyBill.amount / Number(1.19),
                      ).toFixed(3)}{" "}
                      DNT
                    </dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      TIMBRE :
                    </dt>
                    <dd className="col-span-2 text-gray-500">1.000 DNT</dd>
                  </dl>
                  <dl className="grid sm:grid-cols-5 gap-x-2">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Total:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {Number(CompanyBill.amount + 1).toFixed(3)} DNT
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <h4 className="text-xs font-semibold text-gray-800 mt-6 text-center">
              Arrete la presente facture a la somme de{" "}
              {sommeEnLettre.includes("Dinars")
                ? sommeEnLettre.toUpperCase()
                : `${sommeEnLettre.toUpperCase()} DINARS`}
            </h4>
            {CompanyBill.note && (
              <h4 className="text-xs font-semibold text-gray-400 mt-2 text-center">
                Note: {CompanyBill.note}
              </h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
