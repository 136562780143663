import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

import moment from "moment/moment";
import { Tag } from "antd";
import { RentContracts } from "../../../../../Types";
import React from "react";
import { CircleCheckBig, HandCoins } from "lucide-react";

export const RentListingColumns: (
  t: any,
) => (ColumnGroupType<RentContracts> | ColumnType<RentContracts>)[] = (t) => [
  {
    title: t("reference"),
    dataIndex: "referance",
    align: "center",
    key: "referance",
    render: (value, contract) => {
      if (contract.isCanceled) {
        return "-";
      }
      return String(value).padStart(8, "0");
    },
  },
  {
    title: t("client_name"),
    dataIndex: "client",
    align: "center",
    key: "client",
    render: (value) => {
      return value.fullName || value.denomination;
    },
  },
  {
    title: t("start_date"),
    align: "center",
    dataIndex: "startDate",
    key: "startDate",
    render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
  },

  {
    title: t("END_DATE_ESTIMATED"),
    align: "center",
    dataIndex: "endDate",
    key: "endDate",
    render: (value: string) => moment(value).format("DD/MM/YYYY HH:mm"),
  },
  {
    title: t("end_date"),
    align: "center",
    dataIndex: "endDateEffective",
    key: "endDate",
    render: (value: string) =>
      value ? moment(value).format("DD/MM/YYYY HH:mm") : "-",
  },
  {
    title: t("status"),
    dataIndex: "rentContractID",
    align: "center",
    key: "rentContractID",
    render: (value: string, record: RentContracts) => {
      if (record.isReturned) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="green">{t("FINISHED").toUpperCase()}</Tag>
          </div>
        );
      }
      if (record.isCanceled) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="red">{t("CANCELED").toUpperCase()}</Tag>
          </div>
        );
      }

      if (Boolean(moment(new Date()).diff(moment(record.startDate)) < 0)) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="red">{t("PLANED").toUpperCase()}</Tag>
          </div>
        );
      }
      if (
        Boolean(
          moment(new Date()).diff(moment(record.startDate)) > 0 &&
            moment(new Date()).diff(moment(record.endDate)) < 0,
        )
      ) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="orange">{t("ON_GOING").toUpperCase()}</Tag>
          </div>
        );
      }
      if (
        Boolean(
          moment(new Date()).diff(record.endDate) > 0 && record.isReturned,
        )
      ) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="green">{t("FINISHED").toUpperCase()}</Tag>
          </div>
        );
      }
      if (
        moment(record.endDate).isBefore(
          moment(new Date()).add(1, "hour"),
          "hour",
        )
      ) {
        return (
          <span className="mt-1 flex items-center justify-center">
            <Tag color="magenta">{t("DELAYED_RETURN").toUpperCase()}</Tag>
          </span>
        );
      }
    },
  },

  {
    title: t("PAYMENTS_DUE"),
    dataIndex: "rentContractID",
    align: "center",
    key: "PAYMENTS_DUE",
    width: 200,
    render: (value, record) => {
      const {
        dayPrice,
        dayCount,
        extraPrice,
        RentContractPayement,
        isCanceled,
        isReturned,
      } = record;

      const payementsDone = (() => {
        return RentContractPayement.reduce((prev: any, curr: any) => {
          return prev + curr.Amount;
        }, 0);
      })();

      const amountToBePayed = (() => {
        return extraPrice
          ? dayPrice * dayCount + extraPrice
          : dayPrice * dayCount;
      })();

      return isCanceled ? (
        "-"
      ) : (
        <div
          className="flex  gap-2  justify-center items-center text-xs"
          style={{
            backgroundColor:
              amountToBePayed - payementsDone > 0 ? "#f8d7da" : "#d4edda",
            padding: "8px",
            borderRadius: "4px",
            textAlign: "center",
          }}
        >
          {amountToBePayed - payementsDone > 0 ? (
            <HandCoins size={16} />
          ) : (
            <CircleCheckBig size={16} />
          )}
          {`${Number(amountToBePayed - payementsDone).toFixed(3)} / ${Number(amountToBePayed).toFixed(3)} ${t("TND")} `}
        </div>
      );
    },
  },
  {
    title: t("agenceName"),
    dataIndex: "agence",
    align: "center",
    key: "agenceName",
    render: (value) => {
      return value ? value.agenceName : "-";
    },
  },
];
