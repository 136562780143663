import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GradingIcon from "@mui/icons-material/Grading";
import { Button, Divider, Result, Spin, Steps } from "antd";
import { Formik } from "formik";
import SelectCarToRent from "./SelectCarToRent";
import SelectDriversToRent from "./SelectDriversToRent";
import ContractInformations from "./ContractInformations";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { RentContracts } from "../../../../../Types";
import { RecapRentContract } from "./RecapRentContract";
import { Link, useSearchParams } from "react-router-dom";
import { useCompaniesStatsData } from "../../../../../Hooks/useCompaniesStatsData";
import { calculateRentalDays } from "../../../../../Utils";

export interface RentInitialValues {
  addedContract?: RentContracts;
  contract: {
    clientID: null | string;
    startDate: null | string | Date;
    endDate: null | string | Date;
    dayPrice: null | number;
    kmCount: null | number;
    FuelCount: null | number;
    contractReferance?: null | number;
    isdayPriceChanged?: boolean;
    comment: null | string;
    galleryBefore: [] | string[];
    galleryAfter: [] | string[];
    isRentWithDriver: boolean;
    paymentMethod?: null | any;
    calculated_days?: null | number;
    extraDescription?: null | string;
    extraPrice?: null | number;
    pickUpLocation?: null | string;
  };
  drivers:
    | []
    | {
        DriverID: string;
        driverType: "FIRST" | "SECOND";
      };
  fleetID: null | string;
  caution:
    | null
    | {
        RentContractCautionID: string;
        Type: "BANK_TRANSFER" | "CHEQUE" | "ESPECE" | "BANK_CARD";
        Amount: string;
        TRN: string | null;
        Bank: string | null;
        Deposit: string;
      }[];
}

export function RentContractContent() {
  const { t } = useTranslation();

  const [params] = useSearchParams();

  const { companiesStats, isLoading } = useCompaniesStatsData();

  const [currentStepper, setCurrentStepper] = useState<number>(0);

  const stepperItems = [
    {
      title: t("select_car"),
      icon: <DirectionsCarFilledIcon />,
    },
    {
      title: t("select_drivers"),
      icon: <PersonSearchIcon />,
    },
    {
      title: t("contract_informations"),
      icon: <ListAltIcon />,
    },
    {
      title: t("recap"),
      icon: <GradingIcon />,
    },
  ];

  const initialValues: RentInitialValues = {
    contract: {
      clientID: params.get("ClientID"),
      startDate: params.get("startDate"),
      endDate: params.get("endDate"),
      dayPrice: null,
      kmCount: null,
      isdayPriceChanged: false,
      FuelCount: 0.5,
      comment: null,
      galleryBefore: [],
      galleryAfter: [],
      calculated_days:
        params.get("startDate") && params.get("endDate")
          ? calculateRentalDays(
              params.get("startDate") as string,
              params.get("endDate") as string,
            )
          : null,
      isRentWithDriver: false,
      ...(params.get("extraDescription")
        ? { extraDescription: params.get("extraDescription") }
        : {}),
      extraPrice: params.get("extraPrice")
        ? Number(params.get("extraPrice"))
        : null,
    },
    drivers: [],
    fleetID: null,
    caution: [],
  };

  if (isLoading) {
    return (
      <div className="p-10 flex flex-col min-h-fit justify-center items-center w-full">
        <Spin />
      </div>
    );
  }

  return (
    <div className="p-10 flex flex-col min-h-fit justify-center items-center">
      <Formik
        initialValues={initialValues}
        onSubmit={(_, { resetForm }) => {
          resetForm();
          setCurrentStepper(0);
        }}
      >
        {({ values }) => {
          if (companiesStats?.point === 0 && !values.addedContract) {
            return (
              <div className="p-10 flex flex-col min-h-fit justify-center items-center w-full">
                <Result
                  status="error"
                  title={t("insufficient_points")}
                  subTitle={t("refill_points_description")}
                  extra={[
                    <Link to="/" key="back">
                      <Button type="primary">{t("back")}</Button>
                    </Link>,
                    <Link
                      to="/points_management/history"
                      key="transaction_history"
                    >
                      <Button>{t("transaction_history")}</Button>
                    </Link>,
                  ]}
                />
              </div>
            );
          } else {
            return (
              <>
                <div className="w-full">
                  <Steps
                    size="small"
                    className="site-navigation-steps"
                    current={currentStepper}
                    items={stepperItems}
                  />
                </div>
                <Divider />
                {(() => {
                  switch (currentStepper) {
                    case 0:
                      return (
                        <SelectCarToRent
                          setCurrentStepper={setCurrentStepper}
                        />
                      );
                    case 1:
                      return (
                        <SelectDriversToRent
                          setCurrentStepper={setCurrentStepper}
                        />
                      );
                    case 2:
                      return (
                        <ContractInformations
                          setCurrentStepper={setCurrentStepper}
                        />
                      );
                    case 3:
                      return <RecapRentContract />;
                    default:
                      return null;
                  }
                })()}
              </>
            );
          }
        }}
      </Formik>
    </div>
  );
}
