import React from "react";
import moment from "moment";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Users } from "../../../../../Types";
import { Avatar, Switch, Tooltip } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  userChangeStatusHandler,
  userDeleteHandler,
} from "../../../../../Utils/userUtils";

export const CompanyUserListingColumns: (
  t: any,
  userID: string,
  mutate: any,
  openModal?: any,
) => (ColumnGroupType<Users> | ColumnType<Users>)[] = (
  t,
  userID,
  mutate,
  openModal,
) => [
  {
    title: "",
    dataIndex: "avatar",
    key: "avatar",
    render: (value) => {
      const avatar =
        (value &&
          (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
            (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
            value) ||
        "";
      return (
        <Avatar
          size="large"
          alt={value?.name}
          src={avatar}
          crossOrigin={"anonymous"}
        />
      );
    },
  },
  {
    title: t("name"),
    dataIndex: "name",
    key: "name",
  },
  {
    title: t("lastName"),
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: t("auth_email"),
    dataIndex: "email",
    key: "email",
  },
  {
    title: t("agenceName"),
    dataIndex: "AgencesUsers",
    key: "AgencesUsers",
    render: (value) => {
      return value && value.length ? value[0].agenceID.agenceName : "-";
    },
  },
  {
    title: t("last-active"),
    dataIndex: "lastActive",
    key: "lastActive",
    render: (value) =>
      (value && moment(value).format("DD/MM/YYYY HH:mm")) || "-",
  },
  {
    title: t("isActive"),
    dataIndex: "isActive",
    key: "isActive",
    render: (value, record) => {
      return (
        <Switch
          defaultValue={value}
          disabled={record.userID === userID}
          onChange={async () => {
            await userChangeStatusHandler(record.userID, !value);
          }}
        />
      );
    },
  },
  {
    title: "2FA",
    dataIndex: "isOtpEnabled",
    key: "isOtpEnabled",
    render: (value, record) => {
      return (
        <div className="w-full flex justify-center items-center">
          {value ? (
            <CheckCircleFilled style={{ fontSize: 19, color: "#27ae60" }} />
          ) : (
            <CloseCircleFilled style={{ fontSize: 19, color: "#c0392b" }} />
          )}
        </div>
      );
    },
  },
  {
    title: t("actions"),
    dataIndex: "userID",
    key: "userID",
    render: (value) => {
      return (
        <div className="w-full flex justify-center items-center gap-4">
          {value !== userID && (
            <Tooltip title={t("delete_user")}>
              <DeleteIcon
                className="cursor-pointer"
                onClick={async () => {
                  await userDeleteHandler(value);
                  mutate();
                }}
              />
            </Tooltip>
          )}

          <Tooltip title={t("user_activity")}>
            <WorkHistoryIcon
              className="cursor-pointer"
              onClick={() => openModal(value)}
            />
          </Tooltip>
        </div>
      );
    },
  },
];
