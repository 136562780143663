import { MenuProps } from "antd";
import React from "react";
import { FundOutlined, MonitorOutlined } from "@ant-design/icons";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Users as UsersT } from "../../../Types";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Blend,
  Building2,
  Car,
  Coins,
  Contact,
  FileAxis3d,
  FileCheck2,
  FileSpreadsheet,
  FolderOpen,
  Gauge,
  Layers3,
  NotepadText,
  UserRound,
  Users,
  Wallet,
} from "lucide-react";

export type MenuItem = Required<MenuProps>["items"][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const sideBarMenu: (t: any, user: UsersT | null) => MenuItem[] = (
  t,
  user,
) => {
  let itemsList: MenuItem[] = [
    getItem(t("locasafe_dashboard"), "/", <Gauge size={16} />),
  ];

  if (!user) {
    return [];
  }

  const PLATFORM_MENU = [
    getItem(
      t("users_management"),
      "/users_management/users",
      <Users size={16} />,
    ),
    getItem(t("companies_management"), "", <BusinessIcon />, [
      getItem(t("add_company"), "/locasafe_management/add_company"),
      getItem(t("company_listing"), "/locasafe_management/company_listing"),
    ]),
    getItem(t("invoices_management"), "/invoices_management", <ReceiptIcon />, [
      // getItem(t("invoices_dashboard"), "/invoices_management"),
      getItem(t("add_invoices"), "/invoices_management/add_invoices"),
      getItem(t("invoices_listing"), "/invoices_management/invoices_listing"),
    ]),
    getItem(t("bills_management"), "/bills_management/", <DescriptionIcon />, [
      getItem(t("add_bill"), "/bills_management/add_bill"),
      getItem(t("bills_listing"), "/bills_management/bills_listing"),
      //   getItem(t("bills_payements"), "/bills_management/bills_payements"),
    ]),
    getItem(
      t("subscription_management"),
      "/subscription_management",
      <ReceiptLongIcon />,
      [
        getItem(
          t("subscription_prices"),
          "/subscription_management/subscription_prices",
        ),
      ],
    ),
    getItem(t("rne_lookup"), "/rne_lookup", <MonitorOutlined />),
  ];
  const ENTREPRISE_MENU = [
    getItem(
      t("company_management"),
      "/company_management",
      <Building2 size={16} />,
      [
        getItem(t("company_management"), "/company"),
        getItem(t("users_management"), "/users_management/users", null),
        ...(!user.AgencesUsers.length
          ? [
              getItem(t("agence_management"), "/agence_management", null, [
                getItem(t("add_agence"), "/agence_management/add_agence"),
                getItem(
                  t("agence_listing"),
                  "/agence_management/agence_listing",
                ),
              ]),
            ]
          : []),
        ...(!user.AgencesUsers.length
          ? [getItem(t("locasafe_bills"), "/locasafe/bills_listing")]
          : []),
        getItem(t("points_management"), "/points_management", null, [
          getItem(t("transaction_history"), "/points_management/history"),
        ]),
      ],
    ),

    getItem(
      t("RESOURCES_MANAGEMENT"),
      "/resources_management",
      <Blend size={16} />,
      [
        getItem(t("fleet_management"), "/fleet_management", <Car size={16} />, [
          getItem(t("fleet_listing"), "/fleet_management/fleet_listing"),
          getItem(t("add_fleet"), "/fleet_management/add_fleet"),
        ]),

        getItem(
          t("clients_management"),
          "/clients_management",
          <Contact size={16} />,
          [
            getItem(t("add_client"), "/clients_management/add_client"),
            getItem(
              t("clients_listing"),
              "/clients_management/clients_listing",
            ),
          ],
        ),

        getItem(t("drivers_management"), "/drivers", <UserRound size={16} />, [
          getItem(t("add_driver"), "/drivers/add_driver"),
          getItem(t("company_drivers"), "/drivers/company_drivers"),
        ]),
      ],
    ),

    getItem(
      t("RENT_AND_RESERVATION"),
      "/rent_reservation_management",
      <FolderOpen size={16} />,
      [
        getItem(
          t("rent_management"),
          "/rent_management",
          <Layers3 size={16} />,
          [
            getItem(t("add_newRent"), "/rent_management/add_newRent"),
            getItem(t("rent_listing"), "/rent_management/rent_listing"),
            getItem(t("fleet_Scheduler"), "/fleet_management/fleet_Scheduler"),
          ],
        ),
        getItem(
          t("reservation_management"),
          "/reservation_management",
          <NotepadText size={16} />,
          [
            getItem(
              t("add_reservation"),
              "/reservation_management/add_reservation",
            ),
            getItem(
              t("reservation_listing"),
              "/reservation_management/reservation_listing",
            ),
          ],
        ),
      ],
    ),
    getItem(
      t("FINANCIAL_MANAGEMENT"),
      "/financial_management",
      <Wallet size={16} />,
      [
        getItem(
          t("bills_management"),
          "/bills_management",
          <FileAxis3d size={16} />,
          [
            getItem(t("add_bill"), "/bills_management/add_bill"),
            getItem(t("bills_listing"), "/bills_management/bills_listing"),
          ],
        ),

        getItem(
          t("invoices_management"),
          "/invoices_management",
          <FileSpreadsheet size={16} />,
          [
            getItem(t("add_invoices"), "/invoices_management/add_invoices"),
            getItem(
              t("invoices_listing"),
              "/invoices_management/invoices_listing",
            ),
          ],
        ),
        getItem(t("cash_management"), "/cash_management", <Coins size={16} />, [
          getItem(t("cash_management_dashBoard"), "/cash_management/dashboard"),
          getItem(
            t("cash_transaction_listing"),
            "/cash_management/cash_transaction_listing",
          ),
        ]),
        getItem(t("wtcs"), "/wtc", <FileCheck2 size={16} />, [
          getItem(t("add_wtc"), "/wtc/add_wtc"),
          getItem(t("wtc_listing"), "/wtc/wtc_listing"),
        ]),
      ],
    ),
  ];

  const CONSULTANT_MENU = user?.isConsultantAdmin
    ? [
        getItem(t("consultant_management"), "/consultant/", <GroupWorkIcon />, [
          getItem(t("search_history"), "/consultant/history", <FundOutlined />),
          getItem(
            t("users_management"),
            "/users_management/users",
            <PeopleAltIcon />,
          ),
        ]),
      ]
    : [];

  if (user.accountType === "PLATFORM") {
    itemsList = [...itemsList, ...PLATFORM_MENU];
  }

  if (user.accountType === "CONSULTANT") {
    itemsList = [...itemsList, ...CONSULTANT_MENU];
  }

  if (user.accountType === "ENTREPRISE") {
    itemsList = [...itemsList, ...ENTREPRISE_MENU];
  }

  return itemsList;
};
