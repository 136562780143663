import React, { useState } from "react";
import { useRentReturnsData } from "../../../../Hooks/useRentReturnsData";
import { Badge, Modal, Spin, Tag } from "antd";
import { useTranslation } from "react-i18next";
import FlagIcon from "@mui/icons-material/Flag";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import moment from "moment";
import { RentContracts } from "../../../../Types";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Contact, Phone } from "lucide-react";
import { RentPrintableContract } from "../../../UI/Printables/RentPrintableContract";
import { useMediaQuery } from "@mui/material";

export function CompanyDashboardPlanning() {
  const { t } = useTranslation();

  const interval = 1000 * 15;
  const { returnRent, isLoading, mutate } = useRentReturnsData(interval);
  const [selectedContract, setSelectedContract] =
    useState<null | RentContracts>(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const handleRefreshList = () => {
    mutate();
  };
  if (isLoading) {
    return (
      <div className="w-full flex-col md:flex-row   min-h-50   flex justify-center items-center gap-4 p-4">
        <Spin />
      </div>
    );
  }

  return (
    <div className="w-full flex-col     h-fit     flex justify-evenly gap-4  ">
      <Modal
        width={isSmallDevice ? "100%" : "70%"}
        open={Boolean(selectedContract)}
        destroyOnClose={true}
        onCancel={() => setSelectedContract(null)}
        onClose={() => setSelectedContract(null)}
        footer={null}
      >
        <RentPrintableContract contract={selectedContract} showPrint={false} />,
      </Modal>
      <div className="w-full  flex justify-end items-center gap-4 px-8 ">
        <AutorenewIcon
          onClick={() => handleRefreshList()}
          className="cursor-pointer"
        />
      </div>

      <div className="w-full flex-col md:flex-row  h-fit min-h-96   flex justify-evenly gap-4 p-6">
        {returnRent
          ? Object.entries(returnRent).map(([key, { contracts, count }]) => {
              const contactsToDisplay = contracts.sort((a, b) =>
                moment(a.endDate).diff(moment(b.endDate)),
              );
              return (
                <section
                  key={key}
                  className="flex flex-col px-4 pt-4 pb-3 shadow-sm bg-[#ecf0f1] w-full md:w-[320px] md:max-w-[320px]  rounded-xl"
                >
                  <header className="flex gap-10 justify-between items-center w-full h-fit">
                    <h2 className="gap-2 self-stretch text-base font-medium whitespace-nowrap text-neutral-500">
                      {t(key.toUpperCase())}
                    </h2>
                    <Badge count={count} />
                  </header>
                  {contactsToDisplay.map((contract: RentContracts) => {
                    return (
                      <main
                        className="flex flex-col mt-4 w-full"
                        key={contract.rentContractID}
                      >
                        <article
                          className="flex overflow-hidden flex-col p-4 w-full bg-white rounded-md shadow cursor-pointer"
                          onClick={() => {
                            setSelectedContract(contract);
                          }}
                        >
                          <div className="flex gap-2 items-start max-w-full text-xs font-medium text-blue-600 min-h-[24px] w-[211px]">
                            <span className="overflow-hidden gap-1.5 self-stretch px-1.5 py-1 bg-indigo-50 rounded-md">
                              {contract.rentedCar.registrationNumber.replace(
                                "/",
                                ` ${t("TU")} `,
                              )}
                            </span>
                            <span className="overflow-hidden gap-1.5 self-stretch px-1.5 py-1 bg-blend-darken rounded-md">
                              {contract.rentedCar.manufacturer.replace(
                                "_",
                                " ",
                              )}{" "}
                              - {contract.rentedCar.modal}
                            </span>
                          </div>
                          {moment(contract.endDate).isBefore(
                            moment(new Date()).add(1, "hour"),
                            "hour",
                          ) ? (
                            <span className="mt-1 flex items-center justify-center">
                              <Tag color="magenta">{t("DELAYED_RETURN")}</Tag>
                            </span>
                          ) : null}

                          <div className="flex justify-between items-start mt-2 w-full">
                            <div className="flex flex-col min-w-[240px] w-[303px]">
                              <h3 className="flex text-base font-medium text-stone-900 items-center">
                                <Contact size={15} className="mr-2" />{" "}
                                <span>
                                  {contract.client.fullName ||
                                    contract.client.denomination}
                                </span>
                              </h3>
                              <h3 className=" flex  text-sm text-stone-900 items-center">
                                <Phone size={15} className="mr-2" />{" "}
                                <span> {contract.client.phone}</span>
                              </h3>
                              {contract.ContractDrivers.map(
                                (contractDriver) => {
                                  return (
                                    <p className="mt-2 text-xs tracking-normal leading-5 text-gray-800   ">
                                      {contractDriver.driver.fullName} <br />
                                      {t(contractDriver.driver.idType || "")}
                                      <span className="pl-5 font-bold">
                                        {contractDriver.driver.idNumber}
                                      </span>
                                    </p>
                                  );
                                },
                              )}
                            </div>
                          </div>

                          <div className="flex gap-10 justify-between items-center mt-4 w-full text-xs font-medium whitespace-nowrap max-w-[303px]">
                            <div className="flex gap-2 items-center self-stretch my-auto leading-loose text-right text-gray-500">
                              <FlagIcon color="primary" />

                              <time
                                dateTime={moment(contract.startDate).format(
                                  "DD/MM/YYYY",
                                )}
                                className="self-stretch my-auto"
                              >
                                {moment(contract.startDate).format(
                                  "DD/MM/YYYY",
                                )}
                              </time>
                            </div>

                            <span className="self-stretch my-auto leading-6 text-neutral-500">
                              {moment(contract.startDate).format("HH:MM")}
                            </span>
                          </div>
                          <div className="flex gap-10 justify-between items-center   w-full text-xs font-medium whitespace-nowrap max-w-[303px]">
                            <div className="flex gap-2 items-center self-stretch my-auto leading-loose text-right text-gray-500">
                              <KeyboardReturnIcon color="error" />

                              <time
                                dateTime={moment(contract.endDate).format(
                                  "DD/MM/YYYY",
                                )}
                                className="self-stretch my-auto"
                              >
                                {moment(contract.endDate).format("DD/MM/YYYY")}
                              </time>
                            </div>

                            <span className="self-stretch my-auto leading-6 text-neutral-500">
                              {moment(contract.endDate).format("HH:MM")}
                            </span>
                          </div>

                          <footer className="flex gap-2 items-center mt-4 w-full">
                            <div className="flex flex-1 shrink gap-2 items-center self-stretch my-auto text-xs font-medium text-gray-500 basis-0  ">
                              <div className="flex gap-3.5 self-stretch my-auto  ">
                                {contract.agence ? (
                                  <div className="flex gap-1.5">
                                    <ApartmentIcon fontSize="small" />

                                    <span>{contract.agence.agenceName}</span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </footer>
                        </article>
                      </main>
                    );
                  })}
                </section>
              );
            })
          : null}
      </div>
    </div>
  );
}
