import { Button, Tooltip } from "antd";
import React from "react";
import { Download } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useFleetData } from "../../../../../Hooks";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Fleet } from "../../../../../Types";

declare module "jspdf" {
  interface jsPDF {
    lastAutoTable?: {
      finalY: number;
    };
    autoTable: (options: any) => void;
  }
}

function ExportDailyFleetReport() {
  const { t } = useTranslation();
  const { fleet, isLoading } = useFleetData();

  function generateDailyFleetReport() {
    if (!fleet)
      return { carsInRent: [], carsAvailable: [], carsInMaintenance: [] };

    const today = moment().startOf("day");
    const todayEnd = moment().endOf("day");

    const carsInRent = fleet.filter(
      (vehicle) =>
        vehicle.status === "AVAILABLE" &&
        vehicle.RentContract.some((contract) => {
          const isActiveToday =
            moment(contract.startDate).isSameOrBefore(todayEnd, "day") &&
            !contract.endDateEffective &&
            moment(contract.endDate).isSameOrAfter(today, "day");

          return !contract.isCanceled && isActiveToday;
        }),
    );

    const carsAvailable = fleet.filter(
      (vehicle) =>
        vehicle.status === "AVAILABLE" &&
        vehicle.RentContract.every((contract) => {
          return (
            contract.isCanceled ||
            moment(contract.endDateEffective || contract.endDate).isBefore(
              today,
              "day",
            ) ||
            moment(contract.startDate).isAfter(todayEnd, "day")
          );
        }),
    );

    const carsInMaintenance = fleet.filter(
      (vehicle) => vehicle.status === "IN_MAINTENANCE",
    );

    return {
      carsInRent,
      carsAvailable,
      carsInMaintenance,
    };
  }

  function exportReportAsPDF(reportData: {
    carsInRent: any[];
    carsAvailable: any[];
    carsInMaintenance: any[];
  }) {
    const doc = new jsPDF();
    const today = moment().format("DD/MM/YYYY HH:mm ");
    doc.setFontSize(12);
    doc.text(`Rapport journalier de la flotte - ${today}`, 10, 10);

    const generateSection = (
      title: string,
      data: Fleet[] | [],
      showClient: Boolean,
    ) => {
      const startY = doc.lastAutoTable?.finalY
        ? doc.lastAutoTable.finalY + 10
        : 20;
      doc.text(title, 10, startY);

      const tableData = data.map((item, index) => {
        return [
          index + 1,
          item.registrationNumber.replaceAll("/", " TU ") || "N/A",
          item.manufacturer + " " + item.modal.toUpperCase() || "N/A",
          item.kilometrage || "N/A",
          ...(showClient
            ? [
                item.RentContract[0].client.denomination ||
                  item.RentContract[0].client.fullName,
                item.RentContract[0].client.phone,
                moment(item.RentContract[0].startDate).format(
                  "DD/MM/YYYY HH:mm",
                ),
                moment(item.RentContract[0].endDate).format("DD/MM/YYYY HH:mm"),
                Number(
                  (() => {
                    return item.RentContract[0].extraPrice
                      ? item.RentContract[0].dayPrice *
                          item.RentContract[0].dayCount +
                          item.RentContract[0].extraPrice
                      : item.RentContract[0].dayPrice *
                          item.RentContract[0].dayCount;
                  })() -
                    (() => {
                      return item.RentContract[0].RentContractPayement.reduce(
                        (prev: any, curr: any) => {
                          return prev + curr.Amount;
                        },
                        0,
                      );
                    })(),
                ).toFixed(3),
              ]
            : [
                t(item.color),
                t(item.fuel || "") || "N/A",
                t(item.transmission) || "N/A",
                t(item.status) || "N/A",
              ]),
        ];
      });

      doc.autoTable({
        startY: startY + 10,
        head: [
          [
            "#",
            "Matricule",
            "Model",
            "Kilometrage",
            ...(showClient
              ? [
                  "Client",
                  "Telephone",
                  "Date debut",
                  "Date Fin",
                  "Reste à payer",
                ]
              : ["Couleur", "Carburant", "Transmission", "Status"]),
          ],
        ],
        body: tableData,
        headStyles: { fillColor: [51, 51, 51] }, // Dark gray header color
        styles: { fontSize: 8 },
      });
    };

    // Generate sections for each report category
    generateSection("Voitures en Location", reportData.carsInRent, true);
    generateSection("Voitures Disponibles", reportData.carsAvailable, false);
    generateSection(
      "Voitures en maintenance",
      reportData.carsInMaintenance,
      false,
    );

    // Save the PDF file
    doc.save(`Daily_Fleet_Report_${today}.pdf`);
  }

  return (
    <Tooltip title={t("EXPORT_TODAY_FLEET_REPORT")}>
      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs  !text-white  flex flex-wrap"
        onClick={() => {
          exportReportAsPDF(generateDailyFleetReport());
        }}
        disabled={isLoading}
      >
        <Download size={18} />
      </Button>
    </Tooltip>
  );
}

export default ExportDailyFleetReport;
