import React from "react";
import { useTranslation } from "react-i18next";
import { FolderOpenDot } from "lucide-react";
import { Descriptions, DescriptionsProps, Tag } from "antd";
import { useMediaQuery } from "@mui/material";
import { RentContracts } from "../../../../../../Types";
import moment from "moment";
import { calculateRentalDays } from "../../../../../../Utils";

interface RentGeneralInfosProps {
  RentContract: RentContracts;
}

const RentGeneralInfos: React.FC<RentGeneralInfosProps> = ({
  RentContract,
}) => {
  const { t } = useTranslation();

  const {
    referance,
    client,
    startDate,
    endDate,
    dayPrice,
    dayCount,
    kmCount,
    kilometrageReturn,
    extraDescription,
    extraPrice,
    isRentWithDriver,
    comment,
    pickUpLocation,
    endDateEffective,
  } = RentContract;
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: `${t("reference")}`,
      children: (
        <Tag color="geekblue">{String(referance).padStart(8, "0")}</Tag>
      ),
      span: 2,
    },
    {
      key: "2",
      label: `${t("client_name")}`,
      children: client.fullName || client.denomination,
      span: 2,
    },
    {
      key: "3",
      label: `${t("start_date")}`,
      children: moment(startDate).format("DD/MM/YYYY HH:mm"),
      span: 2,
    },
    {
      key: "4",
      label: `${t("end_date")}`,
      children: moment(endDateEffective ?? endDate).format("DD/MM/YYYY HH:mm"),
      span: 2,
    },
    {
      key: "5",
      label: `${t("dayPrice")}`,
      children: `${Number(dayPrice).toFixed(3)} ${t("TND")}`,
      span: 2,
    },
    {
      key: "6",
      label: `${t("NB_DAYS_CALCULATED")}`,
      children: `${calculateRentalDays(startDate, endDate)} ${t("days")}`,
      span: 2,
    },
    {
      key: "7",
      label: `${t("NB_DAYS_BILLED")}`,
      children: `${dayCount} ${t("days")}`,
      span: 2,
    },
    {
      key: "8",
      label: `${t("Total")}`,
      children: `${Number(dayCount * dayPrice).toFixed(3)} ${t("TND")}`,
      span: 2,
    },
    {
      key: "9",
      label: `${t("KILOMETRAGE_AT_PICKUP")}`,
      children: `${kmCount} ${t("KM")}`,
      span: 2,
    },
    {
      key: "10",
      label: `${t("KILOMETRAGE_AT_RETURN")}`,
      children: kilometrageReturn ? `${kilometrageReturn} ${t("KM")}` : "-",
      span: 2,
    },
    {
      key: "11",
      label: `${t("KILOMETRAGE_USED")}`,
      children: kilometrageReturn
        ? `${kilometrageReturn - kmCount} ${t("KM")}`
        : "-",
      span: 2,
    },
    {
      key: "12",
      label: `${t("AVERAGE_KILOMETRAGE_PER_DAY")}`,
      children: kilometrageReturn
        ? `${Number((kilometrageReturn - kmCount) / calculateRentalDays(startDate, endDate)).toFixed(0)} ${t("KM")}`
        : "-",
      span: 2,
    },

    {
      key: "13",
      label: `${t("extra_description")}`,
      children: extraDescription || "-",
      span: 4,
    },
    {
      key: "14",
      label: `${t("extra_price")}`,
      children: extraPrice
        ? `${Number(extraPrice).toFixed(3)} ${t("TND")}`
        : "-",
      span: 2,
    },
    {
      key: "15",
      label: `${t("isRentWithDriver")}`,
      children: isRentWithDriver ? (
        <Tag color="geekblue">{t("yes")}</Tag>
      ) : (
        <Tag color="magenta">{t("no")}</Tag>
      ),
      span: 2,
    },
    {
      key: "16",
      label: `${t("PICKUP_LOCATION")}`,
      children: pickUpLocation || "-",
      span: 2,
    },
    {
      key: "17",
      label: `${t("RENT_COMMENT")}`,
      children: comment,
      span: 6,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Descriptions
      title={
        <div className=" flex items-center justify-between  !text-[16px] gap-4 text-[#d35400]">
          <div className="gap-4 flex items-center">
            <FolderOpenDot size={20} color="#000000" />
            <span> {t("CONTRACT_GENERAL_INFORMATION").toUpperCase()}</span>
          </div>
        </div>
      }
      layout={"vertical"}
      items={items}
      size="small"
      column={isSmallDevice ? 4 : 8}
    />
  );
};

export default RentGeneralInfos;
