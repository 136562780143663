import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, DatePicker, Form, Input, Result, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import UploadWithDrag from "../../../UI/UploadWithDrag";
import { useTranslation } from "react-i18next";
import { useEnumsData } from "../../../../Hooks";
import { enumsToSelectOptions, nationality } from "../../../../Utils";
import moment from "moment";
import { toast } from "react-toastify";
import { Axios } from "../../../../Config";
import dayjs from "dayjs";
import AddDriverFromClientModal from "../../../UI/addDriverFromClientModal";
import { Clients } from "../../../../Types";

export function AddClientContent({
  setAddedClient,
  setIsModalOpen,
}: {
  setAddedClient?: any;
  setIsModalOpen?: any;
}) {
  const { t } = useTranslation();
  const { enums, isLoading: isEnumsLoading } = useEnumsData();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const [client, setClient] = useState<null | Clients>(null);

  const initialValues = {
    clientType: null,
    fullName: null,
    idType: null,
    denomination: null,
    vatNumber: null,
    idNumber: null,
    idDeliveredDate: null,
    nationality: "TUNISIENNE",
    dateOfBirth: null,
    address: null,
    city: null,
    email: null,
    phoneCode: "+216",
    phone: null,
    idRecto: null,
    idVerso: null,
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        idType:
          values.clientType === "PM" || values.clientType === "SA"
            ? "RNE"
            : values.idType,
      };

      const addedClient = await Axios.post("/clients", data).then(
        (res) => res.data.message,
      );
      formikHelpers.setValues(initialValues);

      setBtnIsLoading(false);

      setClient(addedClient);
      if (setAddedClient) {
        setAddedClient(addedClient);
      }
      if (setIsModalOpen) {
        setIsModalOpen(false);
      } else {
        setIsSuccess(true);
      }
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="w-full  overflow-scroll p-10 ">
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, formikHelpers) => {
            onSubmitHandler(values, formikHelpers);
          }}
        >
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem name="clientType" label={t("categorie")}>
                  <Select
                    placeholder={t("categorie")}
                    loading={isEnumsLoading}
                    disabled={isEnumsLoading}
                    options={enumsToSelectOptions(enums?.["ClientTypes"])}
                  />
                </FormItem>

                {values.clientType === "PP" && (
                  <>
                    <FormItem name="fullName" label={t("fullName")}>
                      <Input placeholder={t("fullName")} />
                    </FormItem>
                    <FormItem name="idType" label={t("idType")}>
                      <Select
                        placeholder={t("idType")}
                        loading={isEnumsLoading}
                        options={enumsToSelectOptions(enums?.["IDTypes"])}
                      ></Select>
                    </FormItem>
                    <FormItem name="idNumber" label={t("idNumber")}>
                      <Input placeholder={t("idNumber")} />
                    </FormItem>
                    <FormItem name="nationality" label={t("nationality")}>
                      <Select
                        placeholder={t("nationality")}
                        showSearch
                        filterOption={(input, option): boolean => {
                          if (!option?.value) {
                            return false;
                          }

                          const lowerInput = input.toLowerCase();
                          const { label, value } = option as {
                            value: String;
                            label: String;
                          };
                          return (
                            label?.toLowerCase().includes(lowerInput) ||
                            value?.toLowerCase().includes(lowerInput) ||
                            false
                          );
                        }}
                        loading={isEnumsLoading}
                        disabled={isEnumsLoading}
                        options={enumsToSelectOptions(nationality)}
                      />
                    </FormItem>
                    <FormItem name="idDeliveredDate" label={t("deliveredDate")}>
                      <DatePicker
                        placeholder={t("deliveredDate")}
                        format="DD/MM/YYYY"
                        maxDate={dayjs(new Date())}
                        style={{ width: "100%" }}
                        onChange={(_, day) => {
                          setFieldValue(
                            "idDeliveredDate",
                            moment(day, "DD/MM/YYYY").toISOString(),
                          );
                        }}
                      />
                    </FormItem>

                    <FormItem name="dateOfBirth" label={t("dateOfBirth")}>
                      <DatePicker
                        placeholder={t("dateOfBirth")}
                        format="DD/MM/YYYY"
                        maxDate={dayjs(new Date()).subtract(18, "year")}
                        style={{ width: "100%" }}
                        onChange={(_, day) => {
                          setFieldValue(
                            "dateOfBirth",
                            moment(day, "DD/MM/YYYY").toISOString(),
                          );
                        }}
                      />
                    </FormItem>

                    <FormItem
                      label={
                        values.nationality !== "TUNISIENNE"
                          ? t("entry_stamp_tunisian_territory")
                          : t("copie_verso")
                      }
                    >
                      <UploadWithDrag
                        uploadText={
                          values.nationality !== "TUNISIENNE"
                            ? t("entry_stamp_tunisian_territory")
                            : t("idCard")
                        }
                        hint={
                          values.nationality !== "TUNISIENNE"
                            ? t("entry_stamp_tunisian_territory_hint")
                            : t("copie_verso")
                        }
                        maxCount={1}
                        setFileValue={(file) => {
                          setFieldValue("idVerso", file?.fileID);
                        }}
                      />
                    </FormItem>
                  </>
                )}
                {(values.clientType === "PM" || values.clientType === "SA") && (
                  <>
                    <FormItem
                      name="denomination"
                      label={t("denominationLatin")}
                    >
                      <Input />
                    </FormItem>

                    <FormItem name="vatNumber" label={t("matricule_fiscal")}>
                      <Input />
                    </FormItem>
                  </>
                )}
                {values.clientType ? (
                  <>
                    <FormItem
                      label={
                        values.nationality !== "TUNISIENNE"
                          ? t("PASSPORT")
                          : t("copie_recto")
                      }
                    >
                      <UploadWithDrag
                        uploadText={
                          values.nationality !== "TUNISIENNE"
                            ? t("PASSPORT")
                            : t(values.clientType === "PP" ? "idCard" : "RNE")
                        }
                        hint={t("copie_recto")}
                        maxCount={1}
                        onRemove={() => {
                          setFieldValue("idRecto", "");
                        }}
                        setFileValue={(file) => {
                          setFieldValue("idRecto", file?.fileID);
                        }}
                      />
                    </FormItem>
                    <FormItem name="address" label={t("address")}>
                      <Input placeholder={t("address")} />
                    </FormItem>

                    <FormItem name="city" label={t("city")}>
                      <Input placeholder={t("city")} />
                    </FormItem>

                    <FormItem name="email" label={t("auth_email")}>
                      <Input placeholder={t("auth_email")} />
                    </FormItem>

                    <FormItem name="phone" label={t("phone")}>
                      <Input placeholder={t("phone")} />
                    </FormItem>
                    <div className="w-full flex justify-center">
                      <Button
                        type="primary"
                        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                        onClick={submitForm}
                        disabled={
                          values.clientType == "PP"
                            ? !Boolean(values.idRecto && values.idVerso)
                            : !Boolean(values.idRecto)
                        }
                        loading={isBtnLoading}
                      >
                        {t("add_client").toUpperCase()}
                      </Button>
                    </div>
                  </>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("client_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4  "
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
            ...(client && client?.clientType === "PP"
              ? [<AddDriverFromClientModal client={client} showButton={true} />]
              : []),
          ]}
        />
      )}
    </div>
  );
}
