import React, { useState } from "react";
import { read, utils } from "xlsx";
import { Button, Divider, Modal, Spin, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { Axios, baseURL } from "../../../../../Config";
import { saveAs } from "file-saver";
import { addBulkFleetColumns } from "./addBulkFleetColumns";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";

const validationSchema = yup.object({
  num: yup.string().required("num is required"),
  serie: yup.string().required("serie is required"),
  manufacturer: yup.string().required("Manufacturer is required"),
  modal: yup.string().required("Modal is required"),
  numberHorses: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  type: yup.string().optional(),
  section: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  fuel: yup.string().optional(),
  places: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  vin: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  purchase_price: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  price_per_day: yup.number().required("Price per day is required"),
  status: yup.string().optional(),
  transmission: yup.string().optional(),
  operatingCardNumber: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  operatingCardValidMonthes: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  operatingCardValidFrom: yup
    .date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .optional(),
  color: yup.string().optional(),
});

function AddBulkFleet({ open, setOpen, mutate }: any) {
  const [validContent, setValidContent] = useState<any>(null);
  const [unvalidContent, setUnvalidContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e?.target?.result;
        try {
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet).slice(1);
          console.log(json);

          const validRows = [];
          const invalidRows = [];

          for (const row of json) {
            try {
              await validationSchema.validate(row);
              validRows.push(row);
            } catch (error) {
              console.log(error);
              invalidRows.push({ row, error });
            }
          }

          setValidContent(validRows);
          setUnvalidContent(invalidRows);
        } catch (error) {
          toast.error(t("FILE_READ_PROCESS_ERROR"));
          console.log(error);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const DEMO_FILE_URL = `${baseURL}/bulk_fleet_file/model_bulk_fleet.xlsx`;
  const saveFile = (e: any) => {
    e.preventDefault();
    saveAs(DEMO_FILE_URL, "model_bulk_fleet.xlsx");
  };

  const handleClose = () => {
    setOpen(false);
    setValidContent(null);
    setUnvalidContent(null);
  };

  const onSubmitHandler = async () => {
    try {
      setIsLoading(true);
      const fleetData = validContent?.map((fleet: any) => {
        return {
          ...fleet,
          registrationNumber: `${fleet.serie}/${fleet.num}`,
          firstRegistration: moment(
            fleet.firstRegistration,
            "DD/MM/YYYY",
          ).toISOString(),
          operatingCardValidFrom: moment(
            fleet.operatingCardValidFrom,
            "DD/MM/YYYY",
          ).toISOString(),
        };
      });
      await Axios.post("/enterprise/fleet", { fleet: fleetData });
      if (mutate) {
        mutate();
      }
      toast.success(t("bulk_fleet_added_successfully"));
      setIsLoading(false);
      handleClose();
    } catch (e: any) {
      setIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const columns = addBulkFleetColumns(t);
  return (
    <>
      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
        onClick={() => setOpen(true)}
      >
        {t("add_fleet_bulk").toUpperCase()}
      </Button>
      <Modal
        style={{ top: 20 }}
        title={t("add_fleet_bulk").toUpperCase()}
        width={isSmallDevice ? "100% " : "70%"}
        open={open}
        destroyOnClose={true}
        onCancel={handleClose}
        onClose={handleClose}
        footer={null}
      >
        {isLoading ? (
          <div className="p-10 flex flex-col  h-[600px] items-center justify-center">
            <Spin />
          </div>
        ) : (
          <div className="p-10 flex flex-col max-h-[600px] overflow-auto">
            <div className="flex w-full pb-6 justify-end">
              <button
                type={"button"}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                onClick={saveFile}
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>{t("download_demo_file")}</span>
              </button>
            </div>

            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-42 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">
                      {t("click_to_upload")}
                    </span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t("only_xlsx_allowed")}
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={readUploadFile}
                />
              </label>
            </div>

            {validContent ? (
              <div className="w-full flex flex-col">
                <Divider />
                <div className=" w-full flex gap-4 justify-around mb-4">
                  <div className="   flex gap-2">
                    <span>{t("total_entries")}</span>{" "}
                    <Tag color="cyan">
                      {validContent.length + unvalidContent.length}
                    </Tag>
                  </div>
                  <div className="  flex gap-2">
                    <span>{t("valid_entries")}</span>{" "}
                    <Tag color="green">{`${validContent.length}/${validContent.length + unvalidContent.length}`}</Tag>
                  </div>
                  <div className="   flex gap-2">
                    <span>{t("rejected_entries")}</span>{" "}
                    <Tag color="red">{`${unvalidContent.length}/${validContent.length + unvalidContent.length}`}</Tag>
                  </div>
                </div>

                <Table
                  title={() => t("valid_entry_list")}
                  columns={columns}
                  dataSource={validContent}
                  size="small"
                  scroll={{ x: 600 }}
                  rootClassName="h-2/3 min-h-2/3 "
                  bordered={false}
                />
                <div className="flex w-full py-6 justify-end">
                  <button
                    type={"button"}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    onClick={onSubmitHandler}
                    disabled={!validContent}
                  >
                    <span>{t("add_fleet_bulk")}</span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Modal>
    </>
  );
}

export default AddBulkFleet;
