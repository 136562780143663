import React from "react";
import { t } from "i18next";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Fleet } from "../../../../../Types";
import RegistrationNumberPlate from "../../../../UI/RegistrationNumberPlate";
import { Tag, Tooltip } from "antd";
import moment from "moment/moment";
import { Disc } from "lucide-react";

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

export const fleetListingColumns: (
  | ColumnGroupType<Fleet>
  | ColumnType<Fleet>
)[] = [
  {
    title: null,
    dataIndex: "fleetID",
    key: "isRented",
    align: "center",
    render: (value: string, record) => {
      const rentContracts = record.RentContract;
      const now = moment();
      const todayEnd = moment().endOf("day");

      //TODO : FIX
      const rentContractToday = rentContracts
        .filter((rent) => {
          const isExcluded = rent.isReturned || rent.isCanceled;
          return !isExcluded;
        })
        .every((rent) => {
          const rentStartDate = moment(rent.startDate);
          const rentEndDate = moment(rent.endDate);
          return rentEndDate.isBefore(now) || rentStartDate.isAfter(todayEnd);
        });

      return (
        <Tooltip title={t(!rentContractToday ? "IN_RENT" : "NOT_IN_RENT")}>
          <Disc
            size={16}
            strokeWidth={3}
            color={!rentContractToday ? "#e74c3c" : "#27ae60"}
          />
        </Tooltip>
      );
    },
  },
  {
    title: t("car_number"),
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    align: "center",
    render: (value: string) => {
      return <RegistrationNumberPlate value={value} />;
    },
  },
  {
    title: t("manufacturer"),
    dataIndex: "manufacturer",
    align: "center",
    render: (value: string) => value.replaceAll("_", " "),
  },
  {
    title: t("car_model"),
    dataIndex: "modal",
    align: "center",
  },
  {
    title: t("power"),
    dataIndex: "numberHorses",
    align: "center",
    render: (value: string) => `${value} ${t("horse_power")}`,
  },
  {
    title: t("color"),
    dataIndex: "color",
    align: "center",
    render: (value: string) => t(value),
  },
  {
    title: t("fuel"),
    dataIndex: "fuel",
    align: "center",
    render: (value: string) => t(value),
  },

  {
    title: t("nb_places"),
    dataIndex: "places",
    align: "center",
  },
  {
    title: t("transmission"),
    dataIndex: "transmission",
    align: "center",

    render: (value) => t(value),
  },
  {
    title: t("status"),
    dataIndex: "status",
    align: "center",
    defaultFilteredValue: ["IN_MAINTENANCE", "IN_INSURANCE", "AVAILABLE"],
    filters: [
      {
        text: t("sold").toUpperCase(),
        value: "SOLD",
      },
      {
        text: t("in_maintenance").toUpperCase(),
        value: "IN_MAINTENANCE",
      },
      {
        text: t("in_insurance").toUpperCase(),
        value: "IN_INSURANCE",
      },
      {
        text: t("available").toUpperCase(),
        value: "AVAILABLE",
      },
    ],
    onFilter: (value, record) => {
      return record.status === value;
    },
    render: (value) => {
      return (
        <Tag color={value === "AVAILABLE" ? "green" : "error"}>
          {t(value.toLowerCase())}
        </Tag>
      );
    },
  },
];
