import React, { useMemo, useState } from "react";
import { Badge, List, Modal, Space, Spin, Tag, Timeline } from "antd";
import { useTranslation } from "react-i18next";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useRentReturnsOnDelayData } from "../../../../Hooks/useRentReturnsOnDelayData";
import { FlagOutlined, RollbackOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { RentPrintableContract } from "../../../UI/Printables/RentPrintableContract";
import { RentContracts } from "../../../../Types";

export function CompanyDashboardReturnOnDelay() {
  const { t } = useTranslation();

  const interval = 1000 * 15;
  const { returnRent, isLoading, mutate } = useRentReturnsOnDelayData(interval);

  const handleRefreshList = () => {
    mutate();
  };

  const rentOnDelayData = useMemo(() => {
    if (!returnRent) {
      return [];
    }
    return returnRent.on_delay.contracts
      .filter((contract) => !contract.isReturned && !contract.isCanceled)
      .map((rentContract, i) => ({
        index: i + 1,
        client:
          rentContract.client.fullName || rentContract.client.denomination,
        clientContact: rentContract.client,
        drivers: rentContract.ContractDrivers,
        startDate: rentContract.startDate,
        endDate: rentContract.endDate,
        car: rentContract.rentedCar,
        rentContract: rentContract,
      }));
  }, [returnRent, isLoading]);

  const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const [selectedContract, setSelectedContract] =
    useState<null | RentContracts>(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  if (isLoading) {
    return (
      <div className="w-full flex-col md:flex-row   min-h-50   flex justify-center items-center gap-4 p-4">
        <Spin />
      </div>
    );
  }

  return (
    <div className=" w-full flex-col     h-fit   items-center  flex justify-evenly gap-4  ">
      <Modal
        width={isSmallDevice ? "100%" : "70%"}
        open={Boolean(selectedContract)}
        destroyOnClose={true}
        onCancel={() => setSelectedContract(null)}
        onClose={() => setSelectedContract(null)}
        footer={null}
      >
        <RentPrintableContract contract={selectedContract} showPrint={false} />,
      </Modal>
      <div className="w-full md:w-2/3 flex justify-end items-center gap-4 px-8 ">
        <AutorenewIcon
          onClick={() => handleRefreshList()}
          className="cursor-pointer"
        />
      </div>

      <div className="w-full  md:w-2/3 flex-col md:flex-row  h-fit min-h-96   flex justify-evenly gap-4  ">
        <List
          itemLayout={isSmallDevice ? "horizontal" : "vertical"}
          size="large"
          rootClassName="!w-full"
          header={<Tag color="magenta">{t("DELAYED_RETURN")}</Tag>}
          dataSource={rentOnDelayData}
          renderItem={(item) => (
            <List.Item
              key={item.client}
              onClick={() => {
                setSelectedContract(item.rentContract);
              }}
              actions={[
                <IconText
                  icon={FlagOutlined}
                  text={moment(item.startDate).format("DD/MM/YYYY HH:mm")}
                  key="list-vertical-star-o"
                />,
                <IconText
                  icon={RollbackOutlined}
                  text={moment(item.endDate).format("DD/MM/YYYY HH:mm")}
                  key="list-vertical-like-o"
                />,
              ]}
              extra={
                <div className="flex flex-col gap-4">
                  <span>{t("rent_divers")}</span>
                  <Timeline
                    items={item.drivers.map((contractDriver) => {
                      return {
                        children: (
                          <p className="mt-2 text-xs tracking-normal leading-5 text-gray-800   ">
                            {contractDriver.driver.fullName} <br />
                            {t(contractDriver.driver.idType || "")}
                            <span className="pl-5 font-bold">
                              {contractDriver.driver.idNumber}
                            </span>
                          </p>
                        ),
                      };
                    })}
                  />
                </div>
              }
            >
              <List.Item.Meta
                avatar={<Badge count={item.index}></Badge>}
                title={
                  <div className="flex gap-2 items-start max-w-full text-xs font-medium text-blue-600 min-h-[24px] w-[211px]">
                    <span className="overflow-hidden gap-1.5 self-stretch px-1.5 py-1 bg-indigo-50 rounded-md">
                      {item.car.registrationNumber.replace("/", ` ${t("TU")} `)}
                    </span>
                    <span className="overflow-hidden gap-1.5 self-stretch px-1.5 py-1 bg-blend-darken rounded-md">
                      {item.car.manufacturer.replace("_", " ")} -{" "}
                      {item.car.modal}
                    </span>
                  </div>
                }
                description={
                  <div className="w-full flex flex-col">
                    <span>{item.client}</span>
                    <span>{item.clientContact.phone}</span>
                    <span>
                      {item.clientContact.vatNumber ||
                        item.clientContact.idNumber}
                    </span>
                    <span>{item.clientContact.address}</span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
