import { Clients } from "../../../../../Types";
import { Button, DatePicker, Input, Modal } from "antd";
import { Formik, FormikHelpers } from "formik";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormItem from "antd/es/form/FormItem";
import UploadWithDrag from "../../../../UI/UploadWithDrag";
import { mutate } from "swr";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment/moment";

function ClientEditModal({
  client,
  closeModal,
}: {
  client: Clients;
  closeModal: () => void;
}) {
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  if (!client) {
    return null;
  }

  const {
    city,
    address,
    email,
    fullName,
    denomination,
    phone,
    clientType,
    idDeliveredDate,
  } = client;

  const initialValues = {
    idRecto: null,
    idVerso: null,
    city,
    address,
    email,
    fullName,
    denomination,
    phone,
    idDeliveredDate,
  };
  const handleEditClient = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);

      await Axios.put(`/clients/${client.clientID}`, values);
      await mutate("/clients");

      formikHelpers.setValues(initialValues).then(() => closeModal());

      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <Modal
      width={isSmallDevice ? "100% " : "60%"}
      open={Boolean(client)}
      destroyOnClose
      footer={null}
      onCancel={closeModal}
    >
      <Formik initialValues={initialValues} onSubmit={handleEditClient}>
        {({ setFieldValue, values, submitForm }) => {
          return (
            <div className="w-full flex flex-col gap-3 p-6">
              <FormItem
                label={t(clientType === "PP" ? "fullName" : "denomination")}
              >
                <Input
                  className="w-full"
                  disabled
                  value={
                    values[clientType === "PP" ? "fullName" : "denomination"]
                  }
                  placeholder={t(
                    clientType === "PP" ? "fullName" : "denomination",
                  )}
                />
              </FormItem>

              <FormItem name="address" label={t("address")}>
                <Input
                  placeholder={t("address")}
                  defaultValue={values.address}
                  onChange={(value) => {
                    setFieldValue("address", value.target.value);
                  }}
                />
              </FormItem>

              <FormItem name="city" label={t("city")}>
                <Input
                  placeholder={t("city")}
                  defaultValue={values.city}
                  onChange={(value) => {
                    setFieldValue("city", value.target.value);
                  }}
                />
              </FormItem>

              <FormItem name="email" label={t("auth_email")}>
                <Input
                  placeholder={t("auth_email")}
                  defaultValue={values.email}
                  onChange={(value) => {
                    setFieldValue("email", value.target.value);
                  }}
                />
              </FormItem>

              <FormItem name="phone" label={t("phone")}>
                <Input
                  placeholder={t("phone")}
                  defaultValue={values.phone}
                  onChange={(value) => {
                    setFieldValue("phone", value.target.value);
                  }}
                />
              </FormItem>

              <FormItem name="idDeliveredDate" label={t("deliveredDate")}>
                <DatePicker
                  placeholder={t("deliveredDate")}
                  format="DD/MM/YYYY"
                  maxDate={dayjs(new Date())}
                  style={{ width: "100%" }}
                  value={dayjs(values.idDeliveredDate)}
                  onChange={(_, day) => {
                    setFieldValue(
                      "idDeliveredDate",
                      moment(day, "DD/MM/YYYY").toISOString(),
                    );
                  }}
                />
              </FormItem>

              <FormItem
                label={
                  client.nationality !== "TUNISIENNE"
                    ? t("PASSPORT")
                    : t("copie_recto")
                }
              >
                <UploadWithDrag
                  uploadText={
                    client.nationality !== "TUNISIENNE"
                      ? t("PASSPORT")
                      : t(client.clientType === "PP" ? "idCard" : "RNE")
                  }
                  hint={t("copie_recto")}
                  maxCount={1}
                  onRemove={() => {
                    setFieldValue("idRecto", "");
                  }}
                  setFileValue={(file) => {
                    setFieldValue("idRecto", file?.fileID);
                  }}
                />
              </FormItem>
              {clientType === "PP" ? (
                <FormItem
                  label={
                    client.nationality !== "TUNISIENNE"
                      ? t("entry_stamp_tunisian_territory")
                      : t("copie_verso")
                  }
                >
                  <UploadWithDrag
                    uploadText={
                      client.nationality !== "TUNISIENNE"
                        ? t("entry_stamp_tunisian_territory")
                        : t("idCard")
                    }
                    hint={
                      client.nationality !== "TUNISIENNE"
                        ? t("entry_stamp_tunisian_territory_hint")
                        : t("copie_verso")
                    }
                    maxCount={1}
                    setFileValue={(file) => {
                      setFieldValue("idVerso", file?.fileID);
                    }}
                  />
                </FormItem>
              ) : null}
              <div className="w-full flex justify-center">
                <Button
                  type="primary"
                  className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                  onClick={submitForm}
                  loading={isBtnLoading}
                >
                  {t("EDIT_CLIENT").toUpperCase()}
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ClientEditModal;
