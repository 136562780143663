import React from "react";
import { Route, Routes } from "react-router-dom";
import { SearchHistoryPage } from "../Pages";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { ConsultantUsersManagementPage } from "../Pages/CompanyPages/ConsultantUsersManagementPage";

export function ConsultantRoutes() {
  return (
    <Routes>
      <Route
        path="/users_management/users"
        element={
          <PrivateRoute>
            <ConsultantUsersManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/consultant/history"
        element={
          <PrivateRoute>
            <SearchHistoryPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
