import React, { useRef, useState } from "react";
import { Fleet } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { BookOpenText, GalleryVerticalEnd, SquarePen } from "lucide-react";
import {
  Descriptions,
  DescriptionsProps,
  Divider,
  InputNumber,
  Popconfirm,
} from "antd";
import { useMediaQuery } from "@mui/material";
import RegistrationNumberPlate from "../../../../UI/RegistrationNumberPlate";
import moment from "moment";
import FormItem from "antd/es/form/FormItem";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";

interface FleetGeneralInfosProps {
  fleet: Fleet | null;
  mutate: any;
}

const FleetGeneralInfos: React.FC<FleetGeneralInfosProps> = ({
  fleet,
  mutate,
}) => {
  const { t } = useTranslation();

  const [operatingCardValidMonthes, setOperatingCardValidMonthes] = useState(
    fleet?.operatingCardValidMonthes || 36,
  );
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("car_number"),
      children: <RegistrationNumberPlate value={fleet?.registrationNumber} />,
      span: 2,
    },
    {
      key: "2",
      label: t("manufacturer"),
      children: fleet?.manufacturer,
      span: 2,
    },
    {
      key: "3",
      label: t("car_model"),
      children: fleet?.modal,
      span: 2,
    },
    {
      key: "4",
      label: t("VIN"),
      span: 2,
      children: fleet?.vin,
    },
    {
      key: "5",
      label: t("first_registration"),
      children: moment(fleet?.firstRegistration).format("DD/MM/YYYY"),
      span: 2,
    },
    {
      key: "6",
      label: t("power"),
      children: fleet?.numberHorses,
      span: 2,
    },
    {
      key: "7",
      label: t("type"),
      children: t(fleet?.type || ""),
      span: 2,
    },

    {
      key: "8",
      label: t("fuel"),
      children: t(fleet?.fuel || ""),
      span: 2,
    },
    {
      key: "9",
      label: t("transmission"),
      children: t(fleet?.transmission || ""),
      span: 2,
    },
    {
      key: "10",
      label: t("nb_places"),
      children: fleet?.places,
      span: 2,
    },

    {
      key: "11",
      label: t("color"),
      children: t(fleet?.color || ""),
      span: 2,
    },

    {
      key: "12",
      label: t("section"),
      children: t(fleet?.section || ""),
      span: 2,
    },
    {
      key: "13",
      label: t("purchase_price"),
      children: `${Number(fleet?.purchase_price).toFixed(3)} ${t("TND")}`,
      span: 2,
    },

    {
      key: "14",
      label: t("price_per_day"),
      children: `${Number(fleet?.price_per_day).toFixed(3)} ${t("TND")}`,
      span: 2,
    },
    {
      key: "15",
      label: t("current_mileage"),
      children: `${fleet?.kilometrage}  ${t("KM")}`,
      span: 2,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const asyncToast = useRef<any>();

  const handleChangeFleetOperationCardeValidation = async () => {
    try {
      asyncToast.current = toast.loading(
        t("UPDATE_OPERATING_CARD_VALID_MONTHS_ONGOING"),
      );
      await Axios.put("/enterprise/fleet/operation-card-validity", {
        fleetID: fleet.fleetID,
        operatingCardValidMonthes,
      });
      await mutate().then(() => {
        toast.update(asyncToast.current, {
          render: t("UPDATE_OPERATING_CARD_VALID_MONTHS_SUCCESS_MESSAGE"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Descriptions
        title={
          <div className=" flex items-center gap-4 text-[#d35400] !text-[16px]">
            <BookOpenText size={20} color="#000000" />
            <span> {t("vehicle_information").toUpperCase()}</span>
          </div>
        }
        layout={"vertical"}
        items={items}
        size="small"
        column={isSmallDevice ? 4 : 8}
      />
      <Divider />
      <Descriptions
        title={
          <div className=" flex items-center gap-4 text-[#d35400] !text-[16px]">
            <GalleryVerticalEnd size={20} color="#000000" />
            <span> {t("OPERATING_CARD").toUpperCase()}</span>
          </div>
        }
        layout={"vertical"}
        items={[
          {
            key: "1",
            label: t("OPERATING_CARD_NUMBER"),
            children: fleet?.operatingCardNumber || "-",
            span: 2,
          },

          {
            key: "2",
            label: t("OPERATING_CARD_VALID_MONTHS"),
            children: fleet?.operatingCardValidMonthes ? (
              <div className="flex gap-4 items-center">
                <span>{`${fleet?.operatingCardValidMonthes} ${t("MONTH")}`}</span>
                <Popconfirm
                  title={t("UPDATE_OPERATING_CARD_VALID_MONTHS")}
                  onConfirm={handleChangeFleetOperationCardeValidation}
                  description={
                    <FormItem
                      label={t("OPERATING_CARD_VALID_MONTHS")}
                      name="operatingCardValidMonthes"
                      layout="vertical"
                      className="!p-4"
                    >
                      <InputNumber
                        controls={false}
                        value={operatingCardValidMonthes}
                        onChange={(value) => {
                          setOperatingCardValidMonthes(value || 0);
                        }}
                        min={0}
                        max={60}
                        addonAfter={t("MONTH")}
                        className="w-full"
                      />
                    </FormItem>
                  }
                >
                  <SquarePen size={17} className="cursor-pointer" />
                </Popconfirm>
              </div>
            ) : (
              "-"
            ),
            span: 2,
          },
          {
            key: "3",
            label: t("OPERATING_CARD_VALID_FROM"),
            children: fleet?.operatingCardValidFrom
              ? moment(fleet?.operatingCardValidFrom).format("DD/MM/YYYY")
              : "-",
            span: 2,
          },
          {
            key: "4",
            label: t("OPERATING_CARD_VALID_TO"),
            children: fleet?.operatingCardValidFrom
              ? moment(fleet?.operatingCardValidFrom)
                  .add(fleet?.operatingCardValidMonthes, "months")
                  .subtract(1, "day")
                  .format("DD/MM/YYYY")
              : "-",
            span: 2,
          },
        ]}
        size="small"
        column={isSmallDevice ? 4 : 6}
      />
    </>
  );
};

export default FleetGeneralInfos;
