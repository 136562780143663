import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, MessageSquare } from "lucide-react";
import { RentContracts } from "../../../../../../Types";
import PayementContent from "./PayementContent/PayementContent";
import { Card, Col, Row, Statistic, StatisticProps } from "antd";
import CountUp from "react-countup";

interface RentPayementProps {
  RentContract: RentContracts | null;
}

const RentPayement: React.FC<RentPayementProps> = ({ RentContract }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const payementsDone = useMemo(() => {
    if (!RentContract) {
      return 0;
    } else {
      const { RentContractPayement } = RentContract;

      return RentContractPayement.reduce((prev: any, curr: any) => {
        return prev + curr.Amount;
      }, 0);
    }
  }, [RentContract]);

  const amountToBePayed = useMemo(() => {
    if (!RentContract) {
      return 0;
    } else {
      const { dayPrice, dayCount, extraPrice } = RentContract;

      return extraPrice
        ? dayPrice * dayCount + extraPrice
        : dayPrice * dayCount;
    }
  }, [RentContract]);

  const formatterWithDecimal: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator=" " decimals={3} decimal={","} />
  );
  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <MessageSquare size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("RENT_PAYMENT_MANAGEMENT").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full overflow-scroll mt-6 flex-col gap-6">
          <Row gutter={[16, 16]} wrap={true} align="stretch" justify="center">
            <Col md={12} lg={12} sm={24} xs={24}>
              <Card className="h-full flex flex-col justify-between">
                <Statistic
                  loading={typeof payementsDone !== "number"}
                  title={t("PAYMENTS_DONE")}
                  value={payementsDone}
                  formatter={formatterWithDecimal}
                  suffix={`/ ${Number(amountToBePayed).toFixed(3)} ${t("TND")}`}
                />
              </Card>
            </Col>
            <Col md={12} lg={12} sm={24} xs={24}>
              <Card className="h-full flex flex-col justify-between">
                <Statistic
                  loading={typeof payementsDone !== "number"}
                  title={t("PAYMENTS_DUE")}
                  value={amountToBePayed - payementsDone}
                  formatter={formatterWithDecimal}
                  suffix={`/ ${Number(amountToBePayed).toFixed(3)} ${t("TND")}`}
                />
              </Card>
            </Col>
          </Row>
          <PayementContent
            RentContract={RentContract}
            showAddBtn={!Boolean(Number(amountToBePayed - payementsDone) <= 0)}
            paymentDue={amountToBePayed - payementsDone}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RentPayement;
