import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import PayementAddModalContent from "./PayementAddModalContent";
import { PayementListingColumns } from "./PayementListingColumns";
import { RentContracts } from "../../../../../../../Types";
import { RecuPrintable } from "../../../../../../UI/Printables/RecuPrintable";
import moment from "moment";

interface RentPayementProps {
  RentContract: RentContracts | null;
  showAddBtn?: Boolean;
  paymentDue?: Number;
}

const PayementContent: React.FC<RentPayementProps> = ({
  RentContract,
  showAddBtn = true,
  paymentDue,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const columns = PayementListingColumns(t);

  const Header = () => {
    return (
      <div className="w-full  flex justify-between items-center px-4">
        <span className="font-bold">
          {t("RENT_PAYMENT_MANAGEMENT").toUpperCase()}
        </span>
        {showAddBtn ? (
          <PlusCircleOutlined
            className="cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          />
        ) : null}
      </div>
    );
  };

  if (RentContract?.Bill) {
    columns.push({
      title: t("actions"),
      dataIndex: "RentContractPayementID",
      align: "center",
      key: "RentContractPayementID",
      render: (value: any, contractPayement: any) => {
        const data = {
          receiptNumber: value,
          date: moment().format("DD/MM/YYYY"),
          companyInfo: {
            name: RentContract?.company.rsLatin || "",
            address: RentContract?.company.mainAddress || "",
            phone: RentContract?.company.phone || "",
            email: RentContract?.company.email || "",
            taxId: RentContract?.company.VAT || "",
          },
          paymentInfo: {
            amount: Number(contractPayement.Amount).toFixed(3),
            invoiceNumber: RentContract?.Bill?.reference || "N/A",
            paymentMethod: t(contractPayement.Type),
            paymentDate: moment(contractPayement.createdAt).format(
              "DD/MM/YYYY",
            ),
            clientName:
              RentContract?.client?.fullName ||
              RentContract?.client?.denomination ||
              "",
            clientAddress: RentContract?.client?.address || "",
          },
        };
        return <RecuPrintable RentContractPayement={data} />;
      },
    });
  }

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen ? (
        <Modal
          footer={null}
          open={isModalOpen}
          onClose={closeModalHandler}
          onCancel={closeModalHandler}
          width={400}
        >
          <PayementAddModalContent
            closeModalHandler={closeModalHandler}
            rentContractID={RentContract?.rentContractID || ""}
            maxAmount={paymentDue}
          />
        </Modal>
      ) : null}
      <div>
        <Table
          key="RentContractPayementID"
          size="small"
          title={Header}
          columns={columns as any}
          dataSource={RentContract?.RentContractPayement || []}
          bordered={false}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
};

export default PayementContent;
