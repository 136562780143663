export function NumberToLetter(number, _, __, options = {}) {
  const { isDinar = true, feminine = false, negative = "moins" } = options;

  const NUMBERS = {
    0: "zéro",
    1: "un",
    2: "deux",
    3: "trois",
    4: "quatre",
    5: "cinq",
    6: "six",
    7: "sept",
    8: "huit",
    9: "neuf",
    10: "dix",
    11: "onze",
    12: "douze",
    13: "treize",
    14: "quatorze",
    15: "quinze",
    16: "seize",
    17: "dix-sept",
    18: "dix-huit",
    19: "dix-neuf",
    20: "vingt",
    30: "trente",
    40: "quarante",
    50: "cinquante",
    60: "soixante",
    70: "soixante-dix",
    80: "quatre-vingt",
    90: "quatre-vingt-dix",
  };

  const SCALES = {
    billion: 1000000000000,
    milliard: 1000000000,
    million: 1000000,
    mille: 1000,
    cent: 100,
  };

  function convertInteger(num, isFeminine = false) {
    // Handle direct mappings (0-19)
    if (num <= 19) {
      return NUMBERS[num];
    }

    // Handle by scale
    for (const [scale, value] of Object.entries(SCALES)) {
      if (num >= value) {
        const quotient = Math.floor(num / value);
        const remainder = num % value;

        // Handle hundreds
        if (scale === "cent") {
          if (quotient === 1 && remainder === 0) return "cent";
          if (quotient === 1)
            return `cent ${convertInteger(remainder, isFeminine)}`;
          if (remainder === 0) return `${NUMBERS[quotient]} cents`;
          return `${NUMBERS[quotient]} cent ${convertInteger(remainder, isFeminine)}`;
        }

        // Handle thousands
        if (scale === "mille") {
          if (quotient === 1 && remainder === 0) return "mille";
          if (quotient === 1)
            return `mille ${convertInteger(remainder, isFeminine)}`;
          if (remainder === 0)
            return `${convertInteger(quotient, isFeminine)} mille`;
          return `${convertInteger(quotient, isFeminine)} mille ${convertInteger(remainder, isFeminine)}`;
        }

        // Handle millions, milliards, billions
        const scaleName = quotient === 1 ? scale : `${scale}s`;
        if (quotient === 1 && remainder === 0) return `un ${scaleName}`;
        if (quotient === 1)
          return `un ${scaleName} ${convertInteger(remainder, isFeminine)}`;
        if (remainder === 0)
          return `${convertInteger(quotient, isFeminine)} ${scaleName}`;
        return `${convertInteger(quotient, isFeminine)} ${scaleName} ${convertInteger(remainder, isFeminine)}`;
      }
    }

    // Handle decades (20-99)
    const tens = Math.floor(num / 10) * 10;
    const ones = num % 10;

    if (tens === 70 || tens === 90) {
      return `${NUMBERS[tens - 10]}-${NUMBERS[10 + ones]}`;
    }

    if (ones === 0) {
      return NUMBERS[tens];
    }

    if (ones === 1 && tens < 80) {
      return `${NUMBERS[tens]}-et-un${isFeminine ? "e" : ""}`;
    }

    return `${NUMBERS[tens]}-${NUMBERS[ones]}`;
  }

  // Validate input
  const num =
    typeof number === "string" ? parseFloat(number.replace(/\s/g, "")) : number;

  if (isNaN(num)) {
    throw new Error("Nombre non valide");
  }

  if (Math.abs(num) > 999999999999999) {
    throw new Error(
      "Dépassement de capacité (-999,999,999,999,999 à 999,999,999,999,999)",
    );
  }

  // Handle negative numbers
  if (num < 0) {
    return `${negative} ${NumberToLetter(Math.abs(num), 0, 0, options)}`;
  }

  // Handle zero
  if (num === 0) {
    return isDinar ? "zéro dinar" : NUMBERS[0];
  }

  // Handle currency format (Dinars and millimes)
  if (isDinar) {
    const dinars = Math.floor(num);
    const millimes = Math.round((num - dinars) * 1000);

    const dinarWord = dinars === 1 ? "dinar" : "dinars";
    const millimeWord = millimes === 1 ? "millime" : "millimes";

    if (dinars === 0) {
      return `${convertInteger(millimes)} ${millimeWord}`;
    }

    if (millimes === 0) {
      return `${convertInteger(dinars)} ${dinarWord}`;
    }

    return `${convertInteger(dinars)} ${dinarWord} et ${convertInteger(millimes)} ${millimeWord}`;
  }

  if (Math.floor(num) !== num) {
    const [intPart, decPart] = num.toString().split(".");
    return `${convertInteger(parseInt(intPart), feminine)} virgule ${convertInteger(parseInt(decPart), feminine)}`;
  }

  return convertInteger(num, feminine);
}
