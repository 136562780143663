import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  MenuProps,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { fleetListingColumns } from "./FleetListingColumns";
import { useFleetData } from "../../../../../Hooks";
import Search from "antd/es/input/Search";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  IssuesCloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { ColumnGroupType } from "antd/es/table";
import { Fleet } from "../../../../../Types";
import { ColumnType } from "antd/lib/table";
import FleetInfosDrawer from "../FleetInfosDrawer";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import AddBulkFleet from "../AddFleet/addBulkFleet";
import ExportDailyFleetReport from "../ExportFleetReports/ExportDailyFleetReport";

function FleetListingContent() {
  const { fleet, isLoading, mutate } = useFleetData();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [search, setSearch] = useState("");
  const [showAvailableToday, setShowAvailableToday] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);

  const dataToDisplay = useMemo(() => {
    return search
      ? fleet &&
          fleet.filter((car) => {
            return Boolean(
              car.registrationNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
                car.modal?.toLowerCase().includes(search.toLowerCase()),
            );
          })
      : fleet;
  }, [fleet, search]);

  const availableTodayCount = useMemo(() => {
    return fleet
      ? fleet.filter((car) => {
          return car.RentContract.every((rent) => {
            const now = moment();
            const todayEnd = moment().endOf("day");
            const rentStartDate = moment(rent.startDate);
            const rentEndDate = moment(rent.endDate);
            return rentEndDate.isBefore(now) || rentStartDate.isAfter(todayEnd);
          });
        })
      : [];
  }, [fleet, showAvailableToday]);

  const asyncToast = useRef<any>();

  const handleRemoveFleet = async (fleetID: string) => {
    try {
      asyncToast.current = toast.loading(t("DELETE_FLEET_ONGOING"));
      await Axios.put("/enterprise/fleet/delete", { fleetID });
      await mutate();
      toast.update(asyncToast.current, {
        render: t("DELETE_FLEET_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [openedFleet, setOpenedFleet] = useState<string | null>(null);
  const openDrawer = (fleetID: string) => {
    setIsDrawerOpen(true);
    setOpenedFleet(fleetID);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setOpenedFleet(null);
  };

  const changeFleetStatus = async (fleetID: string, status: string) => {
    try {
      await Axios.put("/enterprise/fleet/status", {
        fleetID,
        status,
      });

      await mutate();

      toast.success(t("car_status_changed_success"));
    } catch (error: any) {
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const renderActions = (value: string, fleet: Fleet) => {
    let items: MenuProps["items"] = [];

    if (fleet.status === "SOLD") {
      items = [
        {
          label: <span>{t("no_action_to_taken")}</span>,

          key: "5",
        },
      ];
    } else {
      items = [
        {
          label: (
            <span onClick={() => changeFleetStatus(value, "AVAILABLE")}>
              {t("confirm_availability")}
            </span>
          ),
          icon: <CheckCircleOutlined />,
          key: "0",
        },
        {
          label: (
            <span onClick={() => changeFleetStatus(value, "IN_MAINTENANCE")}>
              {t("set_in_maintenance")}
            </span>
          ),
          icon: <MinusCircleOutlined />,
          key: "1",
        },
        {
          label: (
            <span onClick={() => changeFleetStatus(value, "IN_INSURANCE")}>
              {t("set_in_insurance")}
            </span>
          ),
          icon: <MinusCircleOutlined />,
          key: "2",
        },
        {
          label: (
            <span onClick={() => changeFleetStatus(value, "SOLD")}>
              {t("set_sold")}
            </span>
          ),
          icon: <CloseCircleOutlined />,
          key: "3",
        },
      ];
    }

    return (
      <div className="w-full flex justify-center items-center gap-4">
        {fleet.RentContract.length ? null : (
          <Popconfirm
            onConfirm={() => handleRemoveFleet(value)}
            okText={t("yes")}
            description={t("CONFIRM_DELETE_CAR")}
            cancelText={t("no")}
          >
            <DeleteOutlined />
          </Popconfirm>
        )}
        <EyeOutlined onClick={() => openDrawer(value)} />
        <Dropdown menu={{ items }} trigger={["click"]}>
          <IssuesCloseOutlined />
        </Dropdown>
      </div>
    );
  };

  const fleetColumns: (ColumnGroupType<Fleet> | ColumnType<Fleet>)[] =
    fleetListingColumns.concat({
      title: t("actions"),
      dataIndex: "fleetID",
      render: (value: string, record: Fleet) => renderActions(value, record),
    });

  const selectedFleet = useMemo(() => {
    if (!openedFleet) {
      return null;
    } else {
      return fleet?.filter((fleet) => fleet.fleetID === openedFleet)[0];
    }
  }, [openedFleet, fleet]);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full min-h-fit overflow-scroll p-10">
      {isDrawerOpen ? (
        <FleetInfosDrawer
          fleet={selectedFleet}
          mutate={mutate}
          open={isDrawerOpen}
          onClose={closeDrawer}
          width={isSmallDevice ? "100%" : "55%"}
        />
      ) : null}
      <div className="flex gap-4 mb-4 justify-end flex-col md:flex-row min-w-fit">
        <div className="flex gap-4 mb-4 ">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
            onClick={() => {
              navigation("/fleet_management/add_fleet");
            }}
          >
            {t("add_fleet").toUpperCase()}
          </Button>
          <AddBulkFleet open={bulkOpen} setOpen={setBulkOpen} mutate={mutate} />
          <ExportDailyFleetReport />
        </div>
      </div>
      <div className="flex gap-4 mb-4 justify-between">
        <div className="flex gap-4 justify-center items-center">
          <Search
            placeholder={t("search")}
            allowClear
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
          <Tooltip title={t("plate_search_info")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className="flex gap-4">
          {fleet?.length ? (
            <div>
              <Tag color="cyan">
                {t("fleet_count")} :{fleet.length}
              </Tag>
            </div>
          ) : null}
          {fleet?.length ? (
            <div>
              <Tooltip title={t("fleet_available_today")}>
                <Tag
                  color="cyan"
                  onClick={() => {
                    setShowAvailableToday(true);
                  }}
                  className="cursor-pointer inline"
                >
                  {t("fleet_available_today")} :{availableTodayCount.length}
                </Tag>
              </Tooltip>
            </div>
          ) : null}

          {showAvailableToday ? (
            <div>
              <Tag
                onClick={() => {
                  setShowAvailableToday(false);
                }}
              >
                <CloseOutlined />
              </Tag>
            </div>
          ) : null}
        </div>
      </div>
      <Table
        rowKey="fleetID"
        size="small"
        loading={isLoading}
        dataSource={
          showAvailableToday ? availableTodayCount : dataToDisplay || []
        }
        columns={fleetColumns}
        rootClassName="h-2/3 min-h-2/3   "
        bordered={false}
      />
    </div>
  );
}

export default FleetListingContent;
